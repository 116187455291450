import { Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const formatDateTime = (dateTimeStr) => {
  const isUTC = dateTimeStr.endsWith("Z");

  if (!isUTC) {
    dateTimeStr = dateTimeStr + "Z";
  }
  const dateTime = new Date(dateTimeStr);
  const now = new Date();
  const utcNow = new Date(
    Date.UTC(
      now.getUTCFullYear(),
      now.getUTCMonth(),
      now.getUTCDate(),
      now.getUTCHours(),
      now.getUTCMinutes(),
      now.getUTCSeconds(),
      now.getUTCMilliseconds(),
    ),
  );

  const diffMs = utcNow - dateTime;
  const diffMins = Math.round(diffMs / (1000 * 60));

  if (diffMins < 1) {
    return "just now";
  } else if (diffMins === 1) {
    return "1 min ago";
  } else if (diffMins < 60) {
    return `${diffMins} mins ago`;
  } else {
    const diffHours = Math.floor(diffMins / 60);
    if (diffHours < 24) {
      return `${diffHours} hrs ago`;
    } else {
      const diffDays = Math.floor(diffHours / 24);
      if (diffDays < 7) {
        return `${diffDays} days ago`;
      } else {
        const diffWeeks = Math.floor(diffDays / 7);
        if (diffWeeks < 4) {
          return `${diffWeeks} weeks ago`;
        } else {
          const diffMonths = Math.floor(diffDays / 30);
          if (diffMonths < 12) {
            if (diffMonths === 0) return `A month ago`;
            else return `${diffMonths} months ago`;
          } else {
            const diffYears = Math.floor(diffDays / 365);
            if (diffYears === 0) return `A year ago`;
            else return `${diffYears} years ago`;
          }
        }
      }
    }
  }
};

export const CustomTabPanel = ({ children, value, index, ...other }) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component={"span"}>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

export const useStyles = makeStyles({
  root: {
    maxHeight: 550,
    border: "1px solid",
    overflowY: "scroll",
    "&::-webkit-scrollbar": {
      width: "0.4em",
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#888",
      borderRadius: "10px",
    },
  },
  row: {
    "& > td": {
      padding: "30px",
    },
  },
});

export const tableStyles = {
  height: "fit-content",
  "& th": {
    background: "#233044",
    color: "#fff",
  },
  "& td, & th": {
    border: "1px solid #233044",
    fontSize: "18px",
  },
};

export const rowStyles = {
  "&:hover": {
    background: "#f5f5f5",
    cursor: "pointer",
  },
  "& td, & th": {
    border: "1px solid #233044",
    fontSize: "8px",
  },
  "&.Mui-selected": {
    background: "#233044 !important",
    "& td, & th": {
      color: "#fff",
    },
  },
};

const getDynamicMaxHeight = () => {
  const screenHeight = window.innerHeight;
  const offsetTop = window.pageYOffset || document.documentElement.scrollTop;
  return screenHeight - offsetTop - 290;
};

export const useMaxStyles = makeStyles({
  root: {
    maxHeight: getDynamicMaxHeight(),
    border: "1px solid",
    overflowY: "scroll",
    "&::-webkit-scrollbar": {
      width: "0.4em",
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#888",
      borderRadius: "10px",
    },
  },
  row: {
    "& > td": {
      padding: "30px",
    },
  },
});
