import axios from "axios";
import React, { useEffect, useState } from "react";
import { NGROK } from "../../../../APIs";
import {
  Box,
  CircularProgress,
  FormControlLabel,
  List,
  ListItem,
  ListItemText,
  Paper,
  Stack,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import moment from "moment";

import { makeStyles } from "@mui/styles";
import { useMaxStyles } from "../../../../GenericMethods";

const AzureStorages = ({ tenantName }) => {
  const [loading, setLoading] = useState(false);
  const [storages, setStorages] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedAccountId, setSelectedAccountId] = useState("");
  const classes = useMaxStyles();

  const handlePublishToggle = async (event, azureStorageAccountId, id) => {
    const published = event.target.checked;
    setStorages((prevData) =>
      prevData.map((storage) =>
        storage.azureStorageAccountId === azureStorageAccountId
          ? { ...storage, published }
          : storage,
      ),
    );
    let requestData = {
      resourceId: azureStorageAccountId,
      wsTenantName: tenantName,
      flag: event.target.checked,
      type: "STORAGE_ACCOUNT",
    };
    const response = await axios.patch(
      `${NGROK}/api/azureResources/v1/publish`,
      requestData,
    );
  };

  const getLastTwoParts = (inputString) => {
    const parts = inputString.split("/");

    if (parts.length > 2) {
      return parts.slice(-3);
    }
    if (parts.length > 1) {
      parts.slice(-2);
    } else {
      return [parts[0]];
    }
  };

  const selectedStorage = (accountId) => {
    setSelectedAccountId(accountId);
  };
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const fetchStorages = async (isFirst = false, tenantName) => {
    if (isFirst) setLoading(true);
    try {
      const response = await axios.get(
        `${NGROK}/api/azureResources/v1/all?type=STORAGE_ACCOUNT&tenantName=${tenantName}`,
      );
      if (response?.data) {
        setStorages(response?.data);
      }
    } catch (e) {
      setStorages([]);
    }

    setLoading(false);
  };
  const filteredStorageDatas = storages?.filter((storage) =>
    storage?.storageAccountName
      .toLowerCase()
      .includes(searchQuery.toLowerCase()),
  );
  const filteredData = storages.filter(
    (storage) => storage.azureStorageAccountId === selectedAccountId,
  );

  useEffect(() => {
    if (tenantName != null) fetchStorages(true, tenantName);
  }, [tenantName]);
  return (
    <>
      <Stack direction={"row"} spacing={5}>
        <Box width={"40%"}>
          <TableContainer
            component={Paper}
            sx={{
              overflow: "auto",
              height: "100%",
            }}
            className={classes.root}
          >
            <Table
              sx={{
                height: "fit-content",
                "& th": {
                  background: "#233044",
                  color: "#fff",
                  position: "sticky",
                  top: 0,
                  zIndex: 1,
                },
                "& td, & th": {
                  borderBottom: "1px solid #233044",
                  fontSize: "18px",
                },
              }}
              size="large"
              aria-label="sticky table"
              className={classes.table}
            >
              <TableHead>
                <TableRow>
                  <TableCell colSpan={7} align="left">
                    Storages
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {loading ? (
                  <TableRow>
                    <TableCell colSpan={7} align="center">
                      <CircularProgress />
                    </TableCell>
                  </TableRow>
                ) : storages.length > 0 ? (
                  <>
                    <TableRow
                      sx={{
                        position: "sticky",
                        top: "54px",
                        backgroundColor: "#f5f5f5",
                        zIndex: 1,
                      }}
                    >
                      <TableCell colSpan={7}>
                        <TextField
                          variant="outlined"
                          placeholder="Search Storages"
                          value={searchQuery}
                          onChange={handleSearchChange}
                          fullWidth
                        />
                      </TableCell>
                    </TableRow>
                    {filteredStorageDatas.map((storeData, index) => {
                      const lastTwoParts = getLastTwoParts(
                        storeData.azureStorageAccountId,
                      );

                      return (
                        <TableRow
                          key={index}
                          selected={
                            storeData.azureStorageAccountId ===
                            selectedAccountId
                          }
                          onClick={() => {
                            selectedStorage(storeData.azureStorageAccountId);
                          }}
                          sx={{
                            "&:hover": {
                              background: "#f5f5f5",
                              cursor: "pointer",
                            },
                            "&.Mui-selected": {
                              background: "#233044 !important",
                              "& td, & th": {
                                color: "#fff",
                              },
                            },
                            "& td, & th": {
                              borderBottom: "1px solid #233044",
                              fontSize: "18px",
                            },
                          }}
                        >
                          <TableCell>
                            <Box
                              display="flex"
                              alignItems="center"
                              justifyContent={"space-between"}
                            >
                              <Box
                                display="flex"
                                flexDirection="column"
                                alignItems="flex-start"
                              >
                                <Box ml={2}>
                                  <Typography
                                    variant="h6"
                                    sx={{ fontSize: "18px" }}
                                  >
                                    {storeData.storageAccountName}
                                  </Typography>
                                  {lastTwoParts.length > 1 ? (
                                    <Typography
                                      variant="body2"
                                      sx={{ fontSize: "14px", color: "gray" }}
                                    >
                                      Path: {lastTwoParts[0]} /{" "}
                                      {lastTwoParts[1]}
                                    </Typography>
                                  ) : (
                                    <Typography
                                      variant="body2"
                                      sx={{ fontSize: "14px", color: "gray" }}
                                    >
                                      Path: {lastTwoParts[0]}
                                    </Typography>
                                  )}
                                </Box>
                              </Box>

                              <Box ml={2}>
                                <FormControlLabel
                                  control={
                                    <Switch
                                      checked={storeData.published}
                                      onChange={(e) =>
                                        handlePublishToggle(
                                          e,
                                          storeData.azureStorageAccountId,
                                          storeData.id,
                                        )
                                      }
                                      name="publishSwitch"
                                    />
                                  }
                                  label="Publish"
                                />
                              </Box>
                            </Box>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </>
                ) : (
                  <TableRow>
                    <TableCell colSpan={7} align="center">
                      <Typography align="center">No Storage Found</Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        {selectedAccountId && filteredData ? (
          <Box
            width={"50%"}
            sx={{
              border: "1px solid",
            }}
          >
            <Typography
              variant="h6"
              gutterBottom
              sx={{
                background: "#233044",
                color: "#fff",
                padding: "10px",
                borderRadius: "5px",
                fontSize: "18px",
                p: 4.8,
              }}
            >
              Details of {filteredData[0]?.storageAccountName}
            </Typography>
            <List>
              {filteredData.length > 0 ? (
                filteredData.map((storage, index) => (
                  <div key={index}>
                    <ListItem>
                      <ListItemText
                        primary={
                          <Typography sx={{ fontSize: 18 }}>
                            <strong>Account Name</strong>
                          </Typography>
                        }
                        secondary={
                          <Typography sx={{ fontSize: 18 }}>
                            {storage.storageAccountName || "-"}
                          </Typography>
                        }
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        primary={
                          <Typography sx={{ fontSize: 18 }}>
                            <strong>Account ID</strong>
                          </Typography>
                        }
                        secondary={
                          <Typography
                            sx={{
                              fontSize: 18,
                              wordWrap: "break-word",
                              whiteSpace: "normal",
                            }}
                          >
                            {storage.azureStorageAccountId || "-"}
                          </Typography>
                        }
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        primary={
                          <Typography sx={{ fontSize: 18 }}>
                            <strong>Region</strong>
                          </Typography>
                        }
                        secondary={
                          <Typography sx={{ fontSize: 18 }}>
                            {storage.region || "-"}
                          </Typography>
                        }
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        primary={
                          <Typography sx={{ fontSize: 18 }}>
                            <strong>Custom Domain Name</strong>
                          </Typography>
                        }
                        secondary={
                          <Typography sx={{ fontSize: 18 }}>
                            {storage.customDomainName || "-"}
                          </Typography>
                        }
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        primary={
                          <Typography sx={{ fontSize: 18 }}>
                            <strong>Kind</strong>
                          </Typography>
                        }
                        secondary={
                          <Typography sx={{ fontSize: 18 }}>
                            {storage.kind || "-"}
                          </Typography>
                        }
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        primary={
                          <Typography sx={{ fontSize: 18 }}>
                            <strong>Blob Public Access Allowed</strong>
                          </Typography>
                        }
                        secondary={
                          <Typography sx={{ fontSize: 18 }}>
                            {storage.blobPublicAccessAllowed || "-"}
                          </Typography>
                        }
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        primary={
                          <Typography sx={{ fontSize: 18 }}>
                            <strong>Shared Key Access Allowed</strong>
                          </Typography>
                        }
                        secondary={
                          <Typography sx={{ fontSize: 18 }}>
                            {storage.sharedKeyAccessAllowed || "-"}
                          </Typography>
                        }
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        primary={
                          <Typography sx={{ fontSize: 18 }}>
                            <strong>Access Allowed From All Networks</strong>
                          </Typography>
                        }
                        secondary={
                          <Typography sx={{ fontSize: 18 }}>
                            {storage.isAccessAllowedFromAllNetworks || "-"}
                          </Typography>
                        }
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        primary={
                          <Typography sx={{ fontSize: 18 }}>
                            <strong>Public Network Access</strong>
                          </Typography>
                        }
                        secondary={
                          <Typography sx={{ fontSize: 18 }}>
                            {storage.publicNetworkAccess || "-"}
                          </Typography>
                        }
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        primary={
                          <Typography sx={{ fontSize: 18 }}>
                            <strong>Public Access</strong>
                          </Typography>
                        }
                        secondary={
                          <Typography sx={{ fontSize: 18 }}>
                            {storage.publicAccess || "-"}
                          </Typography>
                        }
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        primary={
                          <Typography sx={{ fontSize: 18 }}>
                            <strong>Sku Tier</strong>
                          </Typography>
                        }
                        secondary={
                          <Typography sx={{ fontSize: 18 }}>
                            {storage.skuTier || "-"}
                          </Typography>
                        }
                      />
                    </ListItem>

                    <ListItem>
                      <ListItemText
                        primary={
                          <Typography sx={{ fontSize: 18 }}>
                            <strong>Created At</strong>
                          </Typography>
                        }
                        secondary={
                          <Typography sx={{ fontSize: 18 }}>
                            {moment(storage.createdDate).format(
                              "YYYY-MM-DD HH:mm",
                            ) || "-"}
                          </Typography>
                        }
                      />
                    </ListItem>
                  </div>
                ))
              ) : (
                <Typography variant="body1"> No details available</Typography>
              )}
            </List>
          </Box>
        ) : null}
      </Stack>
    </>
  );
};

export default AzureStorages;
