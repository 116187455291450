import {
  Avatar,
  Button,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
  TableCell,
  Typography,
} from "@mui/material";
import React from "react";
import PublicIcon from "@mui/icons-material/Public";
import moment from "moment";

const getFaviconUrl = (url) => {
  try {
    const domain = new URL(url).hostname;
    return `https://www.google.com/s2/favicons?sz=64&domain=${domain}`;
  } catch {
    return null;
  }
};

const UrlTableCell = ({ url, item }) => {
  const faviconUrl = getFaviconUrl(url);

  return (
    <>
      <TableCell align="left">
        <Stack direction="row">
          <ListItem
            sx={{
              minWidth: 300,
              wordBreak: "break-word",
            }}
          >
            <ListItemAvatar>
              <Avatar
                src={faviconUrl}
                sx={{ width: 32, height: 32 }}
                onError={(e) => (
                  (e.target.onerror = null), (e.target.src = "")
                )}
              >
                <PublicIcon sx={{ fontSize: 32 }} />
              </Avatar>
            </ListItemAvatar>

            <Stack spacing={1}>
              <ListItemText
                primary={
                  <Stack
                    spacing={1}
                    direction={"row"}
                    alignItems={"center"}
                    justifyContent={"flex-start"}
                    sx={{ width: "100%" }}
                  >
                    <Button
                      variant="text"
                      sx={{
                        color: "inherit",
                        fontSize: "15px",
                        padding: 0,
                        minWidth: "auto",
                        textAlign: "left",
                        "&:hover": {
                          textDecoration: "underline",
                        },
                      }}
                    >
                      <Typography
                        variant="body2"
                        sx={{
                          fontWeight: 500,
                          fontSize: 16,
                          wordBreak: "break-word",
                          overflowWrap: "break-word",
                          whiteSpace: "normal",
                          textAlign: "left",
                        }}
                      >
                        {url
                          .toLowerCase()
                          .replace("https://", "")
                          .replace("http://", "")
                          .replace("www.", "")}
                      </Typography>
                    </Button>
                  </Stack>
                }
                secondary={
                  <Stack spacing={1} component={"span"}>
                    <Typography
                      component="span"
                      sx={{ fontSize: "14px", display: "inline" }}
                      color="text.secondary"
                    >
                      {"Created At: "}
                      {moment(item.data || new Date()).format("DD/MM/YYYY")}
                    </Typography>
                    <Typography
                      component="span"
                      sx={{ fontSize: "14px", display: "inline" }}
                      color="text.secondary"
                    >
                      {"Created By: "}
                      {item.createdBy || "-"}
                    </Typography>
                  </Stack>
                }
              />
            </Stack>
          </ListItem>

          <Stack direction="row" spacing={2} alignItems="center"></Stack>
        </Stack>
      </TableCell>
    </>
  );
};

export default UrlTableCell;
