import axios from "axios";
import React, { useEffect, useState } from "react";
import { NGROK } from "../../../../APIs";
import {
  Box,
  CircularProgress,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemText,
  Paper,
  Stack,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

import { GrServerCluster } from "react-icons/gr";
import {
  clusterType,
  KubernetesIcons,
  nameSpaceLevelType,
} from "../../../../K8TypeConstant";
import KubernetesResourcesDetails from "./KubernetesResourcesDetails";
import {
  CustomTabPanel,
  rowStyles,
  tableStyles,
  useMaxStyles,
  useStyles,
} from "../../../../GenericMethods";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";

const K8Resources = ({
  selectedTenantName,
  allapi,
  clusterlevelapi,
  namespacelevelapi,
}) => {
  const classes = useMaxStyles();
  const [tabValue, setTabValue] = useState("");

  const [searchCluster, setSearchCluster] = useState("");
  const [clusters, setClusters] = useState([]);
  const [selectedCluster, setSelectedCluster] = useState(null);

  const [searchTypes, setSearchTypes] = useState("");
  const [types, setTypes] = useState(clusterType);
  const [selectedType, setSelectedType] = useState(null);

  const [showNameSpaceType, setShowNameSpaceType] = useState(false);
  const [nameSpaceTypes, setNameSpaceTypes] = useState(nameSpaceLevelType);
  const [searchNameSpaceCluster, setSearchNameSpaceCluster] = useState("");

  const [resources, setResources] = useState([]);
  const [selectedNameSpaceType, setSelectedNameSpaceType] = useState(null);
  const [selectedNameSpace, setSelectedNameSpace] = useState(null);

  const [nameSpaceResources, setNameSpaceResources] = useState([]);

  const [searchNameSpaces, setSearchNameSpaces] = useState("");

  const fetchAllK8Resources = async () => {
    const response = await axios.get(`${NGROK}${allapi}`);
    if (response.data) {
      let data = response.data;
      setClusters(response.data);
      //setSelectedCluster(data[0]);
    }
  };

  const handleClusterSearchChange = (event) => {
    setSearchCluster(event.target.value);
  };

  const handleTypesSearchChange = (event) => {
    setSearchTypes(event.target.value);
  };

  const callClusterLevelResources = async (nameSpaceLevelType) => {
    const response = await axios.post(`${NGROK}${clusterlevelapi}`, {
      wsTenantName: selectedTenantName,
      clusterId: selectedCluster.azureId,
      cloudProviderType: "AZURE",
      type: nameSpaceLevelType,
      namespace: null,
    });
    if (response.data) {
      setResources(response.data);
    }
  };

  const handleCallforNameLevelTypes = async (type, name) => {
    const response = await axios.post(`${NGROK}${namespacelevelapi}`, {
      wsTenantName: selectedTenantName,
      clusterId: selectedCluster.azureId,
      cloudProviderType: "AZURE",
      type: type.name,
      namespace: name,
    });
    if (response.data) {
      setNameSpaceResources(response.data);
    }
  };

  const handleCall = (type) => {
    if (type === "NAMESPACE") {
      setShowNameSpaceType(true);
      setNameSpaceTypes(nameSpaceLevelType);
    } else {
      setShowNameSpaceType(false);
      setNameSpaceTypes([]);
    }
    callClusterLevelResources(type);
  };

  const filteredClusters = clusters.filter((resource) =>
    resource.name.toLowerCase().includes(searchCluster.toLowerCase()),
  );

  const filteredTypes = types.filter((type) =>
    type.toLowerCase().includes(searchTypes.toLowerCase()),
  );

  const filteredNamespaceTypes = nameSpaceTypes.filter((type) =>
    type.name.toLowerCase().includes(searchTypes.toLowerCase()),
  );

  const filteredAzureK8ResourcesDetails = resources.filter((resource) =>
    resource.name.toLowerCase().includes(searchNameSpaces.toLowerCase()),
  );

  useEffect(() => {
    fetchAllK8Resources();
    setTypes(clusterType);
    setShowNameSpaceType(false);
  }, [selectedTenantName]);
  useEffect(() => {
    setSelectedNameSpaceType(null);
    setSelectedNameSpace(null);
  }, [selectedType]);

  return (
    <>
      <Box
        sx={{
          width: "100%",
        }}
      >
        {selectedCluster ? (
          <>
            <Box
              display="flex"
              alignItems="center"
              gap={2}
              mb={2}
              sx={{
                mx: "auto",
                mt: 3,
                p: 2,
                borderRadius: 2,
              }}
            >
              <IconButton
                onClick={() => {
                  setSelectedCluster(null);
                }}
              >
                <ArrowBackIcon />
              </IconButton>
              <Typography
                variant="h6"
                sx={{
                  fontSize: 18,
                }}
              >
                {selectedCluster.name}
              </Typography>
            </Box>
            <Stack spacing={5} mt={2}>
              <Box
                display="flex"
                justifyContent="space-between"
                sx={{ gap: 2 }}
              >
                <ToggleButtonGroup
                  value={tabValue}
                  exclusive
                  onChange={(event, newValue) => {
                    if (newValue !== null) {
                      setTabValue(newValue);
                      setSelectedType(newValue);
                      handleCall(newValue);
                    }
                  }}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "auto",
                  }}
                >
                  {filteredTypes.map((type, index) => (
                    <ToggleButton
                      key={index}
                      value={type}
                      sx={{ textTransform: "none", fontSize: "16px" }}
                    >
                      {type}
                    </ToggleButton>
                  ))}
                </ToggleButtonGroup>
              </Box>

              {tabValue === "NAMESPACE" ? (
                <Stack direction={"row"} spacing={5}>
                  <Box sx={{ width: "30%" }}>
                    <TableContainer
                      component={Paper}
                      sx={{ overflow: "auto", height: "100%" }}
                      className={classes.root}
                    >
                      <Table
                        sx={tableStyles}
                        size="large"
                        aria-label="sticky table"
                        className={classes.table}
                      >
                        <TableHead>
                          <TableRow>
                            <TableCell>NameSpaces</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow>
                            <TableCell colSpan={1}>
                              <TextField
                                variant="outlined"
                                placeholder="Search Namespace"
                                value={searchNameSpaces}
                                onChange={(event) =>
                                  setSearchNameSpaces(event.target.value)
                                }
                                fullWidth
                              />
                            </TableCell>
                          </TableRow>
                          {filteredAzureK8ResourcesDetails.map(
                            (resourceDetails, index) => (
                              <TableRow
                                key={index}
                                selected={
                                  resourceDetails.uid === selectedNameSpace?.uid
                                }
                                onClick={() =>
                                  setSelectedNameSpace(resourceDetails)
                                }
                                sx={rowStyles}
                              >
                                <TableCell>
                                  <Typography
                                    variant="h6"
                                    sx={{ fontSize: "18px" }}
                                  >
                                    {resourceDetails.name}
                                  </Typography>
                                </TableCell>
                              </TableRow>
                            ),
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>

                  {/* Namespace Types Table */}
                  {selectedNameSpace && (
                    <Box sx={{ width: "30%" }}>
                      <TableContainer
                        component={Paper}
                        sx={{ overflow: "auto", height: "100%" }}
                        className={classes.root}
                      >
                        <Table
                          sx={tableStyles}
                          size="large"
                          aria-label="sticky table"
                          className={classes.table}
                        >
                          <TableHead>
                            <TableRow>
                              <TableCell>NameSpace Types</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <TableRow>
                              <TableCell colSpan={1}>
                                <TextField
                                  variant="outlined"
                                  placeholder="Search NamespaceTypes"
                                  value={searchNameSpaceCluster}
                                  onChange={(event) =>
                                    setSearchNameSpaceCluster(
                                      event.target.value,
                                    )
                                  }
                                  fullWidth
                                />
                              </TableCell>
                            </TableRow>
                            {filteredNamespaceTypes.map(
                              (nameSpaceType, index) => (
                                <TableRow
                                  key={index}
                                  selected={
                                    nameSpaceType?.name ===
                                    selectedNameSpaceType?.name
                                  }
                                  onClick={() => {
                                    setSelectedNameSpaceType(nameSpaceType);
                                    handleCallforNameLevelTypes(
                                      nameSpaceType,
                                      selectedNameSpace?.name,
                                    );
                                  }}
                                  sx={rowStyles}
                                >
                                  <TableCell>
                                    <Stack direction={"row"} spacing={3}>
                                      <Box>{nameSpaceType.icon}</Box>
                                      <Typography variant="h6">
                                        {nameSpaceType.name}
                                      </Typography>
                                    </Stack>
                                  </TableCell>
                                </TableRow>
                              ),
                            )}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Box>
                  )}

                  {/* Kubernetes Resource Details */}
                  {selectedNameSpace && selectedNameSpaceType && (
                    <KubernetesResourcesDetails
                      resources={nameSpaceResources}
                    />
                  )}
                </Stack>
              ) : (
                <Stack direction={"row"} spacing={5}>
                  <KubernetesResourcesDetails resources={resources} />
                </Stack>
              )}
            </Stack>
          </>
        ) : (
          <Stack spacing={5}>
            <Typography
              variant="h6"
              sx={{
                fontSize: 18,
              }}
            >
              <strong>Clusters: </strong>
            </Typography>
            <Stack direction={"row"} spacing={2}>
              <TextField
                placeholder="Search Resources..."
                value={searchCluster}
                onChange={handleClusterSearchChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                variant="outlined"
              />
            </Stack>

            <TableContainer
              component={Paper}
              sx={{
                overflow: "auto",
                height: "100%",
              }}
              className={classes.root}
            >
              <Table
                sx={{
                  height: "fit-content",
                  "& th": {
                    background: "#233044",
                    color: "#fff",
                  },
                  "& td, & th": {
                    border: "1px solid #233044",
                    fontSize: "18px",
                  },
                }}
                size="large"
                aria-label="sticky table"
                className={classes.table}
              >
                <TableHead>
                  <TableRow>
                    <TableCell align="center">Name</TableCell>
                    <TableCell align="center">Region</TableCell>
                    <TableCell align="center">Group Name</TableCell>
                    <TableCell align="center">Type</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredClusters.map((resource) => (
                    <>
                      <TableRow key={resource.name}>
                        <TableCell align="left">
                          <Stack
                            direction={"row"}
                            spacing={5}
                            display="flex"
                            alignItems="center"
                          >
                            <Stack
                              direction={"row"}
                              spacing={2}
                              alignItems={"center"}
                              sx={{
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                setSelectedCluster(resource);
                                setTabValue(clusterType[0]);
                              }}
                            >
                              <Box>
                                <GrServerCluster
                                  style={{
                                    marginRight: 8,
                                    fontSize: "18px",
                                  }}
                                />
                              </Box>
                              <Box ml={2}>
                                <Typography
                                  variant="h6"
                                  sx={{
                                    fontSize: 18,
                                  }}
                                >
                                  {resource.name}
                                </Typography>
                                <Typography
                                  variant="body2"
                                  sx={{
                                    maxWidth: "350px",
                                    wordWrap: "break-word",
                                    whiteSpace: "normal",
                                  }}
                                >
                                  Region : {resource.regionName || "-"}
                                </Typography>
                              </Box>
                            </Stack>
                          </Stack>
                        </TableCell>
                        <TableCell align="center">
                          {resource.regionName || "-"}
                        </TableCell>
                        <TableCell align="center">
                          {resource.resourceGroupName || "-"}
                        </TableCell>
                        <TableCell align="center">
                          {resource.type || "-"}
                        </TableCell>
                      </TableRow>
                    </>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Stack>
        )}
      </Box>
    </>
  );
};

export default K8Resources;
