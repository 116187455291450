import React, { useEffect, useState } from "react";
import { NGROK } from "../../../APIs";
import axios from "axios";
import { myLocalStorage } from "../../../components/StorageHelper";
import useUserStore from "../../../services/userStore";
import SearchIcon from "@mui/icons-material/Search";

import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  Tabs,
  Tab,
  List,
  ListItem,
  ListItemText,
  Switch,
  FormControlLabel,
  IconButton,
} from "@mui/material";
import SecurityIcon from "@mui/icons-material/Security";
import InfoIcon from "@mui/icons-material/Info";
import { makeStyles } from "@mui/styles";
import { awsIcons } from "../AWS/AWSIcons";
import { useMaxStyles } from "../../../GenericMethods";

const useStyles = makeStyles({
  root: {
    maxHeight: 750,
    border: "1px solid",
    overflowY: "scroll",
    "&::-webkit-scrollbar": {
      width: "0.4em",
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#888",
      borderRadius: "10px",
    },
  },
  row: {
    "& > td": {
      padding: "30px",
    },
  },
});
const GcpRoles = () => {
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState("");
  const [selectedRole, setSelectedRole] = useState(null);
  const latestTenant = myLocalStorage.getItem("latestTenant")?.tenantName;
  const [selectedTenantName, setSelectedTenantName] = useState(null);
  const [tenantList, setTenantList] = useState([]);
  const [isSyncing, setIsSyncing] = useState(false);
  const [roles, setRoles] = useState([]);
  const [filter, setFilter] = useState("all");
  const [projectId, setProjectId] = useState(null);
  const [selectedDetailsTab, setSelectedDetailsTab] = useState(0);
  const [pageNo, setPageNo] = useState(1);
  const [reachedEnd, setReachedEnd] = useState(false);
  const [searchString, setSearchString] = useState("");
  const [isrolesLoading, setIsrolesLoading] = useState(false);
  const classes = useMaxStyles();

  const userData = useUserStore((state) => state.user);

  const handlePublishToggle = async (event, roleId) => {
    const isManagedRole = event.target.checked;
    setRoles((prevData) =>
      prevData.map((role) =>
        role.roleId === roleId ? { ...role, isManagedRole } : role,
      ),
    );

    const response = await axios.put(
      `${NGROK}/api/gcp/resource/publishRole?tenantName=${encodeURIComponent(
        selectedTenantName,
      )}&projectId=${encodeURIComponent(
        projectId,
      )}&roleId=${encodeURIComponent(roleId)}`,
    );
  };

  const handleDetailsTabChange = (event, newValue) => {
    setSelectedDetailsTab(newValue);
  };

  const ondemandButton = async () => {
    setIsSyncing(true);
    const response = await axios.get(`${NGROK}/api/gcp-sync/manual`, {
      params: {
        tenantName: selectedTenantName,
      },
    });
    setReachedEnd(false);
    setIsSyncing(false);
  };
  const handleTenantChange = (value) => {
    const tenant = tenantList.find((tenant) => tenant.tenantName === value);
    if (tenant !== undefined) {
      setSelectedTenantName(tenant.tenantName);
      myLocalStorage.setItem("latestTenant", tenant);
    }
  };

  const getAllRoles = async () => {
    let url = `${NGROK}/api/gcp/resource/get-roles?tenantName=${latestTenant}&projectId=${projectId}&page=${pageNo}&size=25`;
    console.log(searchString);
    if (searchString?.length > 0) {
      url += `&searchString=${searchString}`;
    }
    console.log(url);
    const response = await axios.get(url);
    if (response.status === 200) return response.data;
    else return [];
  };

  const fetchData = async (initialLoad = false, infiniteScroll = false) => {
    try {
      const [rolesData] = await Promise.all([getAllRoles()]);
      if (rolesData.length === 0) {
        setReachedEnd(true);
      } else {
        setReachedEnd(false);
      }
      if (infiniteScroll) {
        setRoles((prevRoles) => [...prevRoles, ...rolesData]);
      } else {
        setRoles(rolesData);
      }
      setLoading(false);
      if (initialLoad) {
        const filteredRoles = filterRoles(rolesData, filter);
        let selectRole = filteredRoles.length > 0 ? filteredRoles[0] : null;
        setSelectedRole(selectRole);
        // if (selectRole) getTheSelectedRoleDefinition(selectRole);
      }
    } catch (error) {
      console.error("Error fetching data", error);
      setLoading(false);
    }
  };

  const handleFilterChange = (event, newFilter) => {
    const filteredRoles = filterRoles(roles, newFilter);
    setFilter(newFilter);
  };

  const handleRoleSearchChange = (event) => {
    const value = event.target.value;
    setSearch(value);
    if (!value || value?.trim() === "") {
      setRoles([]);
      setPageNo(1);
      setReachedEnd(false);
      setSearchString("");
      setSelectedRole(null);
    }
  };

  const handleRoleClick = (role) => {
    setSelectedRole(role);
    //getTheSelectedRoleDefinition(role);
  };

  const filterRoles = (roles, filter) => {
    if (filter === "default") {
      return roles?.filter((role) => !role.isCustom);
    } else if (filter === "custom") {
      return roles?.filter((role) => role.isCustom);
    }
    return roles;
  };

  const filteredRoles = filterRoles(
    roles?.filter((role) => role?.roleName),
    filter,
  );

  const handleButtonClick = () => {
    setRoles([]);
    setPageNo(1);
    setReachedEnd(false);
    setSelectedRole(null);
    setSearchString(search);
  };

  const handleScroll = (event) => {
    if (reachedEnd) return;
    setIsrolesLoading(true);
    const bottom =
      event.target.scrollHeight - event.target.scrollTop <=
      event.target.clientHeight + 1;

    if (bottom) {
      setPageNo((prevPage) => prevPage + 1);
    }
  };

  useEffect(() => {
    fetchData(false, true);
  }, [pageNo, searchString]);

  useEffect(() => {
    if (projectId != null) fetchData(true);
  }, [latestTenant, projectId]);

  useEffect(() => {
    if (tenantList.length === 1)
      return setSelectedTenantName(tenantList[0].tenantName);

    if (tenantList?.length > 1) {
      const latestTenantName =
        myLocalStorage.getItem("latestTenant")?.tenantName;

      const tenant = tenantList.find(
        (tenant) => tenant.tenantName === latestTenantName,
      );
      if (tenant) setSelectedTenantName(tenant.tenantName);
      else setSelectedTenantName(tenantList[0].tenantName);
    }
  }, [tenantList]);

  useEffect(() => {
    const fetchTenants = async () => {
      const response = await axios.get(`${NGROK}/api/get-all-domains`);
      setTenantList(response.data);
    };
    fetchTenants();
  }, [userData]);

  const fetchGCPProjectId = async () => {
    const response = await axios.get(`${NGROK}/api/gcp/auth/configuration`, {
      params: {
        tenantName: latestTenant,
      },
    });
    if (response.data && response?.data?.length !== 0) {
      setProjectId(response.data[0].projectId);
      myLocalStorage.setItem(
        "gcpSelectedProjectId",
        response.data[0].projectId,
      );
    } else {
      setProjectId(null);
      myLocalStorage.removeItem("gcpSelectedProjectId");
    }
  };

  useEffect(() => {
    fetchGCPProjectId();
    setReachedEnd(false);
  }, [latestTenant]);

  useEffect(() => {
    const container = document.querySelector(".table-container");
    if (container) {
      if (container.scrollHeight > container.clientHeight + 0.1) {
        handleScroll({ target: container });
      }
    }
  }, []);
  useEffect(() => {
    setReachedEnd(false);
  }, []);

  return (
    <>
      <Box>
        <Typography
          variant="h5"
          gutterBottom
          sx={{
            fontSize: "22px",
          }}
        >
          GCP Roles
        </Typography>
        <Box
          display="flex"
          mt={5}
          mb={5}
          sx={{ width: "100%", justifyContent: "space-between" }}
        >
          <Stack direction={"row"} spacing={2}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "15px",
              }}
            >
              {tenantList?.length > 0 ? (
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  value={selectedTenantName ? selectedTenantName : " "}
                  options={tenantList.map((tenant) => tenant.tenantName)}
                  sx={{ width: 300 }}
                  renderInput={(params) => (
                    <TextField {...params} label="Tenant List" />
                  )}
                  onChange={(e, value) => {
                    handleTenantChange(value);
                  }}
                />
              ) : null}
            </Box>
          </Stack>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              variant="outlined"
              color="primary"
              sx={{ ml: "auto" }}
              disabled={isSyncing}
              onClick={ondemandButton}
            >
              {isSyncing ? "Syncing" : "Sync"}
            </Button>
          </Box>
        </Box>
        {loading ? (
          <Box display={"flex"} p={5}>
            <CircularProgress />
          </Box>
        ) : (
          <>
            <Stack spacing={5} mt={2}>
              <Box
                display="flex"
                justifyContent="space-between"
                sx={{ gap: 2 }}
              >
                <ToggleButtonGroup
                  value={filter}
                  exclusive
                  onChange={handleFilterChange}
                  aria-label="filter toggle buttons"
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "auto",
                  }}
                >
                  <ToggleButton
                    value="all"
                    sx={{
                      fontSize: "18px",
                    }}
                  >
                    All
                  </ToggleButton>
                  <ToggleButton
                    value="default"
                    sx={{
                      fontSize: "18px",
                    }}
                  >
                    Default
                  </ToggleButton>
                  <ToggleButton
                    value="custom"
                    sx={{
                      fontSize: "18px",
                    }}
                  >
                    Custom
                  </ToggleButton>
                </ToggleButtonGroup>
              </Box>
              <Stack direction="row" spacing={5}>
                <Box
                  sx={{
                    width: "35%",
                    minWidth: "300px",
                  }}
                >
                  <TableContainer
                    component={Paper}
                    sx={{
                      overflow: "auto",
                      height: "100%",
                    }}
                    className={[classes.root, "table-container"]}
                    onScroll={handleScroll}
                  >
                    <Table
                      sx={{
                        width: "100%",
                        "& th": {
                          background: "#233044",
                          color: "#fff",
                          position: "sticky",
                          top: 0,
                          zIndex: 1,
                        },
                        "& td, & th": {
                          borderBottom: "1px solid #233044",
                          fontSize: "18px",
                        },
                      }}
                      size="large"
                      aria-label="sticky table"
                      className={classes.table}
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell>Roles Name</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow
                          sx={{
                            position: "sticky",
                            top: "54px",
                            backgroundColor: "#f5f5f5",
                            zIndex: 1,
                          }}
                        >
                          <TableCell>
                            <TextField
                              label="Search Roles"
                              variant="outlined"
                              value={search}
                              onChange={handleRoleSearchChange}
                              fullWidth
                              InputProps={{
                                endAdornment: (
                                  <IconButton onClick={handleButtonClick}>
                                    <SearchIcon />
                                  </IconButton>
                                ),
                              }}
                            />
                          </TableCell>
                        </TableRow>
                        {filteredRoles.length > 0 ? (
                          filteredRoles.map((role) => (
                            <TableRow
                              key={role.id}
                              onClick={() => handleRoleClick(role)}
                              selected={role.id === selectedRole?.id}
                              sx={{
                                "&:hover": {
                                  background: "#f5f5f5",
                                  cursor: "pointer",
                                },
                                "& td, & th": {
                                  borderBottom: "1px solid #233044",
                                  fontSize: "18px",
                                },
                                "&.Mui-selected": {
                                  background: "#233044 !important",
                                  "& td, & th": {
                                    color: "#fff",
                                  },
                                },
                              }}
                            >
                              <TableCell>
                                <Box
                                  display="flex"
                                  alignItems="center"
                                  justifyContent={"space-between"}
                                >
                                  <Box display="flex" alignItems="center">
                                    {awsIcons.roles}
                                    <Box ml={2}>
                                      <Typography
                                        variant="h6"
                                        sx={{
                                          fontSize: "18px",
                                        }}
                                      >
                                        {role.roleName}
                                      </Typography>
                                    </Box>
                                  </Box>
                                  <Box ml={2}>
                                    <FormControlLabel
                                      control={
                                        <Switch
                                          checked={role.isManagedRole}
                                          onChange={(e) =>
                                            handlePublishToggle(e, role.roleId)
                                          }
                                          name="publishSwitch"
                                        />
                                      }
                                      label="Publish"
                                    />
                                  </Box>
                                </Box>
                              </TableCell>
                            </TableRow>
                          ))
                        ) : isrolesLoading && !reachedEnd ? (
                          <TableRow>
                            <TableCell align="center">
                              <CircularProgress />
                            </TableCell>
                          </TableRow>
                        ) : (
                          <TableRow>
                            <TableCell align="center">
                              <Typography
                                variant="h6"
                                sx={{
                                  maxHeight: 500,
                                  alignItems: "center",
                                }}
                              >
                                No Roles Found
                              </Typography>
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
                <Box width="35%">
                  {selectedRole ? (
                    <>
                      <Box
                        sx={{
                          border: "1px solid",
                        }}
                      >
                        <Typography
                          variant="h6"
                          gutterBottom
                          sx={{
                            background: "#233044",
                            color: "#fff",
                            padding: "10px",
                            borderRadius: "5px",
                            fontSize: "18px",
                            p: 4.8,
                          }}
                        >
                          Role Details
                        </Typography>
                        <>
                          <Tabs
                            value={selectedDetailsTab}
                            onChange={handleDetailsTabChange}
                          >
                            <Tab
                              icon={<InfoIcon />}
                              label={
                                <Typography sx={{ fontSize: 18 }}>
                                  Info
                                </Typography>
                              }
                            />
                            <Tab
                              icon={<SecurityIcon />}
                              label={
                                <Typography sx={{ fontSize: 18 }}>
                                  Permissions
                                </Typography>
                              }
                            />
                          </Tabs>
                          {selectedDetailsTab === 0 && (
                            <>
                              <List>
                                <ListItem>
                                  <ListItemText
                                    primary={
                                      <Typography sx={{ fontSize: 18 }}>
                                        <strong>Role Name:</strong>
                                      </Typography>
                                    }
                                    secondary={
                                      <Typography sx={{ fontSize: 18 }}>
                                        {selectedRole?.roleName}
                                      </Typography>
                                    }
                                  />
                                </ListItem>
                                <ListItem>
                                  <ListItemText
                                    primary={
                                      <Typography sx={{ fontSize: 18 }}>
                                        <strong>Role Id:</strong>
                                      </Typography>
                                    }
                                    secondary={
                                      <Typography sx={{ fontSize: 18 }}>
                                        {selectedRole?.roleId}
                                      </Typography>
                                    }
                                  />
                                </ListItem>
                                <ListItem>
                                  <ListItemText
                                    primary={
                                      <Typography sx={{ fontSize: 18 }}>
                                        <strong>Description:</strong>
                                      </Typography>
                                    }
                                    secondary={
                                      <Typography sx={{ fontSize: 18 }}>
                                        {selectedRole?.description}
                                      </Typography>
                                    }
                                  />
                                </ListItem>
                              </List>
                            </>
                          )}
                          {selectedDetailsTab === 1 && (
                            <Box
                              mt={5}
                              sx={{
                                margin: "10px",
                                padding: "10px",
                              }}
                            >
                              <Typography sx={{ fontSize: 18 }}>
                                <strong>Attached Permissions:</strong>
                              </Typography>
                              <List>
                                {selectedRole?.permissions?.length > 0 ? (
                                  selectedRole?.permissions?.map(
                                    (permission, index) => (
                                      <ListItem key={index}>
                                        <ListItemText
                                          secondary={
                                            <Typography sx={{ fontSize: 18 }}>
                                              {permission}
                                            </Typography>
                                          }
                                        />
                                      </ListItem>
                                    ),
                                  )
                                ) : (
                                  <ListItem>
                                    <ListItemText
                                      primary={
                                        <Typography sx={{ fontSize: 18 }}>
                                          No Permission available
                                        </Typography>
                                      }
                                    />
                                  </ListItem>
                                )}
                              </List>
                            </Box>
                          )}
                        </>
                      </Box>
                    </>
                  ) : null}
                </Box>
              </Stack>
            </Stack>
          </>
        )}
      </Box>
    </>
  );
};

export default GcpRoles;
