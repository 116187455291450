import {
  Button,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  List,
  ListItem,
  Radio,
  RadioGroup,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import UrlTableCell from "./UrlTableCell";
import useNotificationsStore from "../../../../services/notificationStore";
import useUserStore from "../../../../services/userStore";
import { NGROK } from "../../../../APIs";
import axios from "axios";
import { myLocalStorage } from "../../../../components/StorageHelper";
import { v4 as uuidv4 } from "uuid";

const UserConfiguredURLs = ({ webUrls, tenantName }) => {
  const [open, setOpen] = useState(false);
  const [requestMessage, setRequestMessage] = useState("");

  const [step, setStep] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedCnUser, setSelectedCnUser] = useState(null);
  const userData = useUserStore((state) => state.user);

  const [usersList, setUsersList] = useState([]);

  const [accessType, setAccessType] = useState("DYNAMIC");
  const [selectedTime, setSelectedTime] = useState("");
  const [selectedWebURl, setSelectedWebURl] = useState("");
  const { userWebUrlNotifications } = useNotificationsStore();

  let userAccess = userData.role;
  const access_token = myLocalStorage.getItem("access_token");

  const handleUserSelect = (cnuser) => {
    if (selectedCnUser?.email === cnuser.email) {
      setSelectedCnUser(null);
    } else {
      setSelectedCnUser(cnuser);
    }
  };

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleClose = () => {
    setRequestMessage("");
    setSelectedTime("");
    setSelectedCnUser(null);
    setOpen(false);
  };

  const handleOpen = (item) => {
    setSelectedWebURl(item?.url);
    setUsersList(item?.auth || []);
    setStep(1);
    setOpen(true);
  };

  const getStringBeforeAtSymbol = (str) => {
    const atIndex = str.indexOf("@");
    return atIndex !== -1 ? str.substring(0, atIndex) : str;
  };

  const handleInputChange = (value) => {
    const regex = /^(?!0)[0-9\b]+$/;
    if (value?.length > 3 || value.includes("e") || value === 0) return;

    if (regex.test(value)) {
      setSelectedTime(value);
    } else if (!value) setSelectedTime("");
  };

  const handleKeyPress = (event) => {
    if (event.key === "+" || event.key === "-") {
      event.preventDefault();
    }
  };

  const handleAccessType = (value) => {
    if (value === "PERMANENT") setSelectedTime("");
    setAccessType(value);
  };

  const handleSubmit = async () => {
    const data = {
      email: userData.email,
      accessUrl: selectedWebURl,
      accessType: "Credentials",
      accessCredential: selectedCnUser?.email,
      accessARN: "arn",
      requestMessage,
      requestStatus: "PENDING",
      selectedTime: selectedTime,
      createdAt: new Date().toISOString(),
      privilegeLevel: accessType,
      tenantName,
    };
    try {
      await axios.post(`${NGROK}/api/websites/weburl-request-access`, data);
      handleClose();
    } catch (error) {
      console.error(error);
    }
  };

  const filterdCnUsers = usersList?.filter((users) =>
    users.email.toLowerCase().includes(searchQuery.toLowerCase()),
  );

  const fetchResourceLevel = userWebUrlNotifications?.filter(
    (userWebUrlNotification) =>
      userWebUrlNotification.requestStatus === "APPROVED",
  );

  const checkAccessTiming = (weburl) => {
    return fetchResourceLevel.find((data) => data.accessUrl === weburl?.url);
  };

  const userloginToUrl = async (item) => {
    const filteredResourceUser = fetchResourceLevel.find(
      (user) => user.accessUrl === item?.url,
    );
    if (filteredResourceUser) {
      const accessCredential = filteredResourceUser.accessCredential;
      const matchedUser = item?.auth.find(
        (user) => user.email === accessCredential,
      );
      const result = matchedUser
        ? {
            email: matchedUser.email,
            authValutTags: matchedUser.authValutTags,
          }
        : null;

      const currentTime = new Date();
      const pendingTime = new Date(filteredResourceUser.accessTimeOverAt);
      const durationInSeconds = Math.floor((pendingTime - currentTime) / 1000);

      const isUTC = filteredResourceUser.accessTimeOverAt.endsWith("Z");
      let dateTimeStr = "";
      if (!isUTC) {
        dateTimeStr = filteredResourceUser.accessTimeOverAt + "Z";
      }
      const dateTime = new Date(dateTimeStr);
      const now = new Date();
      let istOffset = 5.5 * 60;
      let pendingTime_ist = new Date(
        dateTime.getTime() + istOffset * 60 * 1000,
      );
      let currentDate_ist = new Date(now.getTime() + istOffset * 60 * 1000);
      let differenceInMilliseconds = currentDate_ist - pendingTime_ist;

      let { authValutTags, email } = result;
      let randomUUID = uuidv4();
      let payload = {
        tokenpasskey: randomUUID,
        userTag: authValutTags,
        email: email,
        token: access_token,
        url: NGROK,
        executeURL: item.url,
        role: userAccess,
        duration: Math.abs(differenceInMilliseconds),
      };
      const response = await axios
        .get(`${NGROK}/api/websites/save-web-url-click?uuid=${randomUUID}`)
        .then(async () => {
          await axios
            .post("http://localhost:5051/start-automation", payload)
            .then((data) => {})
            .catch((e) => {
              console.log("Error {}", e);
            });
        })
        .catch((e) => {
          console.log("Error {}", e);
        });
    }
  };

  return (
    <>
      <Table
        sx={{
          height: "fit-content",
          maxWidth: "100%",
          "& th": {
            background: "#233044",
            color: "#fff",
          },
          "& td, & th": {
            border: "1px solid #233044",
          },
        }}
        size="large"
      >
        <TableHead>
          <TableRow>
            <TableCell align="center">URL</TableCell>
            <TableCell align="center">Access</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {webUrls.length > 0 ? (
            webUrls.map((item) => {
              const isApproved = checkAccessTiming(item);

              return (
                <TableRow key={item.id}>
                  <UrlTableCell url={item.url} />
                  <TableCell align="center">
                    {isApproved ? (
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => userloginToUrl(item)}
                      >
                        Connect
                      </Button>
                    ) : (
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => handleOpen(item)}
                      >
                        Request access
                      </Button>
                    )}
                  </TableCell>
                </TableRow>
              );
            })
          ) : (
            <TableRow>
              <TableCell colspan={7} align="center">
                No WebUrls found
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle sx={{ fontSize: "18px" }}>
          {step === 1
            ? "Select Credentials"
            : `Access request to ${selectedWebURl}`}
        </DialogTitle>
        <DialogContent>
          {step === 1 ? (
            <>
              <TextField
                label="Search users"
                variant="outlined"
                size="small"
                fullWidth
                value={searchQuery}
                onChange={handleSearch}
              />
              <List
                sx={{
                  maxHeight: "450px",
                  overflowY: "auto",
                  marginTop: "20px",
                }}
              >
                {filterdCnUsers.length === 0 ? (
                  <>
                    <ListItem
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        width: "100%",
                      }}
                    >
                      <Typography variant="h6" align="center">
                        <strong>No credentials available</strong>
                      </Typography>
                    </ListItem>
                  </>
                ) : (
                  filterdCnUsers.map((user, index) => (
                    <ListItem key={index}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={selectedCnUser?.email === user?.email}
                            onChange={() => handleUserSelect(user)}
                          />
                        }
                        label={user?.email}
                      />
                    </ListItem>
                  ))
                )}
              </List>
            </>
          ) : (
            <>
              <Stack spacing={2} mt={3} mb={3}>
                <Typography variant="h6">Credential:</Typography>
                <Stack direction="row" spacing={1} flexWrap="wrap">
                  {selectedCnUser && (
                    <Chip label={selectedCnUser?.email} variant="outlined" />
                  )}
                </Stack>
              </Stack>

              <Stack spacing={4}>
                <FormControl component="fieldset">
                  <Typography variant="h6">Access Type:</Typography>
                  <RadioGroup
                    aria-label="access-type"
                    name="access-type"
                    value={accessType}
                    onChange={(e) => handleAccessType(e.target.value)}
                    row
                  >
                    <FormControlLabel
                      value="PERMANENT"
                      control={<Radio />}
                      label="Permanent"
                    />
                    <FormControlLabel
                      value="DYNAMIC"
                      control={<Radio />}
                      label="Timed"
                    />
                  </RadioGroup>
                </FormControl>

                <Typography variant="h6">Computer Name:</Typography>

                <Stack spacing={2} mt={3}>
                  <Typography variant="h6">Access justification:</Typography>
                  <TextField
                    maxRows={5}
                    minRows={5}
                    inputProps={{ maxLength: 150 }}
                    multiline
                    value={requestMessage}
                    onChange={(e) => setRequestMessage(e.target.value)}
                  />
                </Stack>
                {accessType === "DYNAMIC" ? (
                  <Stack spacing={2}>
                    <Typography variant="h6">Time:</Typography>
                    <TextField
                      inputProps={{
                        inputMode: "numeric",
                        pattern: "[0-9]+",
                      }}
                      onKeyPress={handleKeyPress}
                      value={selectedTime}
                      type="text"
                      fullWidth
                      size="small"
                      label={"Enter time in minutes"}
                      onChange={(e) => handleInputChange(e.target.value)}
                    />
                  </Stack>
                ) : null}
              </Stack>
            </>
          )}
        </DialogContent>
        <DialogActions>
          {step === 1 ? (
            <Button
              onClick={() => setStep(2)}
              color="primary"
              disabled={!selectedCnUser}
            >
              Next
            </Button>
          ) : (
            <>
              <Button onClick={() => setStep(1)} color="primary">
                Back
              </Button>
              <Button onClick={handleClose} color="primary">
                Cancel
              </Button>
              <Button
                onClick={handleSubmit}
                disabled={accessType === "DYNAMIC" && !selectedTime}
                color="primary"
              >
                Submit
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default UserConfiguredURLs;
