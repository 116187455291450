import React, { useState } from "react";
import useUserStore from "../../../../services/userStore";
import ListAllAuthUserModal from "./ListAllAuthUserModal";
import { myLocalStorage } from "../../../../components/StorageHelper";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import { NGROK } from "../../../../APIs";

const AuthPublishPopover = ({ auth }) => {
  const userData = useUserStore((state) => state.user);
  let userAccess = userData.role;
  const access_token = myLocalStorage.getItem("access_token");

  const loginToUrl = async (selectedUser) => {
    let { authValutTags, email } = selectedUser;
    let randomUUID = uuidv4();
    let payload = {
      tokenpasskey: randomUUID,
      userTag: authValutTags,
      email: email,
      token: access_token,
      url: NGROK,
      executeURL: auth.url,
      role: userAccess,
      duration: 0,
    };
    const response = await axios
      .get(`${NGROK}/api/websites/save-web-url-click?uuid=${randomUUID}`)
      .then(async () => {
        await axios
          .post("http://localhost:5051/start-automation", payload)
          .then((data) => {})
          .catch((e) => {
            console.log("Error {}", e);
          });
      })
      .catch((e) => {
        console.log("Error {}", e);
      });
  };
  return (
    <>
      <ListAllAuthUserModal auth={auth} loginToUrl={loginToUrl} />
    </>
  );
};

export default AuthPublishPopover;
