import {
  Box,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemText,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { NGROK } from "../../../../APIs";
import { myLocalStorage } from "../../../../components/StorageHelper";
import { makeStyles } from "@mui/styles";
import { GrServerCluster } from "react-icons/gr";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SearchIcon from "@mui/icons-material/Search";
import { useMaxStyles } from "../../../../GenericMethods";

const K8Roles = ({
  selectedTenantName,
  allapi,
  rolesApi,
  cloud,
  rolespoliciesApi,
}) => {
  const [filter, setFilter] = useState("all");
  const [loading, setLoading] = useState(true);
  const [k8Roles, setK8Roles] = useState([]);
  const classes = useMaxStyles();
  const [search, setSearch] = useState("");
  const [selectedRole, setSelectedRole] = useState(null);
  const [selectedRoleRules, setSelectedRoleRules] = useState([]);
  const [clusterId, setClusterId] = useState(null);
  const [selectedCluster, setSelectedCluster] = useState(null);

  const [clusters, setClusters] = useState([]);
  const [searchCluster, setSearchCluster] = useState("");

  const latestTenant = myLocalStorage.getItem("latestTenant")?.tenantName;

  const handleClusterSearchChange = (event) => {
    setSearchCluster(event.target.value);
  };

  const fetchAllK8Resources = async () => {
    const response = await axios.get(`${NGROK}${allapi}`);
    if (response.data) {
      let data = response.data;
      setClusters(response.data);

      //setSelectedCluster(data[0]);
    }
  };

  const getAllK8Roles = async (filterType, clusterId) => {
    const response = await axios.get(
      `${NGROK}${rolesApi}?clusterId=${clusterId}&tenantName=${latestTenant}&cloud=${cloud}&level=${filterType.toUpperCase()}`,
    );
    if (response.status === 200) return response.data;
    else return [];
  };

  const fetchData = async (initialLoad = false, filterType, clusterId) => {
    try {
      const [roles] = await Promise.all([getAllK8Roles(filterType, clusterId)]);
      setK8Roles(roles);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data", error);
      setLoading(false);
    }
  };

  const handleFilterChange = (event, newFilter) => {
    setSelectedRole(null);
    fetchData(false, newFilter, selectedCluster?.azureId);
    setFilter(newFilter);
  };

  const handleRoleSearchChange = (event) => {
    setSearch(event.target.value);
  };

  const filteredRoles = k8Roles?.filter(
    (role) =>
      role?.name && role?.name.toLowerCase().includes(search.toLowerCase()),
  );
  const filteredClusters = clusters.filter((resource) =>
    resource.name.toLowerCase().includes(searchCluster.toLowerCase()),
  );

  const getRules = async (role) => {
    const response = await axios.get(
      `${NGROK}${rolespoliciesApi}?uid=${role?.uid}&tenantName=${latestTenant}&cloud=${role?.cloudType}&clusterId=${role?.clusterId}`,
    );
    if (response.status === 200) {
      if (response?.data) setSelectedRoleRules(response.data);
      else setSelectedRoleRules([]);
    } else setSelectedRoleRules([]);
  };

  const handleRoleClick = (role) => {
    setSelectedRole(role);
    getRules(role);
  };

  useEffect(() => {
    fetchAllK8Resources();
  }, [latestTenant]);

  return (
    <>
      <Box
        sx={{
          width: "100%",
        }}
      >
        {selectedCluster ? (
          <>
            <Box
              display="flex"
              alignItems="center"
              gap={2}
              mb={2}
              sx={{
                mx: "auto",
                mt: 3,
                p: 2,
                borderRadius: 2,
              }}
            >
              <IconButton
                onClick={() => {
                  setSelectedCluster(null);
                }}
              >
                <ArrowBackIcon />
              </IconButton>
              <Typography
                variant="h6"
                sx={{
                  fontSize: 18,
                }}
              >
                {selectedCluster.name}
              </Typography>
            </Box>
            <Stack spacing={5} mt={2}>
              <Box
                display="flex"
                justifyContent="space-between"
                sx={{ gap: 2 }}
              >
                <ToggleButtonGroup
                  value={filter}
                  exclusive
                  onChange={handleFilterChange}
                  aria-label="filter toggle buttons"
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "auto",
                  }}
                >
                  <ToggleButton
                    value="all"
                    sx={{
                      fontSize: "16px",
                    }}
                  >
                    All
                  </ToggleButton>
                  <ToggleButton
                    value="Cluster"
                    sx={{
                      fontSize: "16px",
                    }}
                  >
                    Cluster
                  </ToggleButton>
                  <ToggleButton
                    value="NameSpace"
                    sx={{
                      fontSize: "16px",
                    }}
                  >
                    NameSpace
                  </ToggleButton>
                </ToggleButtonGroup>
              </Box>
              <Stack direction="row" spacing={5}>
                <Box
                  sx={{
                    width: "38%",
                    minWidth: "320px",
                  }}
                >
                  <TableContainer
                    component={Paper}
                    sx={{
                      overflow: "auto",
                      height: "100%",
                    }}
                    className={classes.root}
                  >
                    <Table
                      sx={{
                        width: "100%",
                        "& th": {
                          background: "#233044",
                          color: "#fff",
                          position: "sticky",
                          top: 0,
                          zIndex: 1,
                        },
                        "& td, & th": {
                          borderBottom: "1px solid #233044",
                          fontSize: "18px",
                        },
                      }}
                      size="large"
                      aria-label="sticky table"
                      className={classes.table}
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell>Roles Name</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow
                          sx={{
                            position: "sticky",
                            top: "54px",
                            backgroundColor: "#f5f5f5",
                            zIndex: 1,
                          }}
                        >
                          <TableCell>
                            <TextField
                              label="Search K8 Roles"
                              variant="outlined"
                              value={search}
                              onChange={handleRoleSearchChange}
                              fullWidth
                            />
                          </TableCell>
                        </TableRow>
                        {filteredRoles.length > 0 ? (
                          filteredRoles.map((role) => (
                            <TableRow
                              key={role.id}
                              onClick={() => handleRoleClick(role)}
                              selected={role.id === selectedRole?.id}
                              sx={{
                                "&:hover": {
                                  background: "#f5f5f5",
                                  cursor: "pointer",
                                },
                                "& td, & th": {
                                  borderBottom: "1px solid #233044",
                                  fontSize: "18px",
                                },
                                "&.Mui-selected": {
                                  background: "#233044 !important",
                                  "& td, & th": {
                                    color: "#fff",
                                  },
                                },
                              }}
                            >
                              <TableCell>
                                <Box
                                  display="flex"
                                  alignItems="center"
                                  justifyContent={"space-between"}
                                >
                                  <Box display="flex" alignItems="center">
                                    <Box ml={2}>
                                      <Typography
                                        variant="h6"
                                        sx={{
                                          fontSize: "18px",
                                        }}
                                      >
                                        {role.name}
                                      </Typography>
                                    </Box>
                                  </Box>

                                  <Box ml={2}></Box>
                                </Box>
                              </TableCell>
                            </TableRow>
                          ))
                        ) : (
                          <TableRow>
                            <TableCell align="center">
                              <Typography variant="h6">
                                No K8 Roles Found
                              </Typography>
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>

                {selectedRole && selectedRoleRules.length > 0 && (
                  <Box
                    width="35%"
                    sx={{
                      border: "1px solid",
                    }}
                  >
                    <Typography
                      variant="h6"
                      sx={{
                        backgroundColor: "#233044",
                        color: "#fff",
                        padding: "10px",
                        fontSize: "18px",
                        borderRadius: "5px",
                      }}
                    >
                      Role Rules
                    </Typography>

                    <List>
                      <ListItem>
                        <ListItemText
                          primary={
                            <Typography sx={{ fontSize: 18 }}>
                              <strong>Cluster Role UID: </strong>
                            </Typography>
                          }
                          secondary={
                            <Typography sx={{ fontSize: 18 }}>
                              {selectedRoleRules[0]?.clusterRoleUID || "-"}
                            </Typography>
                          }
                        />
                      </ListItem>
                      <ListItem>
                        <ListItemText
                          primary={
                            <Typography sx={{ fontSize: 18 }}>
                              <strong>NameSpace Role UID: </strong>
                            </Typography>
                          }
                          secondary={
                            <Typography sx={{ fontSize: 18 }}>
                              {selectedRoleRules[0]?.namespaceRoleUID}
                            </Typography>
                          }
                        />
                      </ListItem>
                      <ListItem>
                        <ListItemText
                          primary={
                            <Typography sx={{ fontSize: 18 }}>
                              <strong>Verbs: </strong>
                            </Typography>
                          }
                          secondary={
                            <Typography sx={{ fontSize: 18 }}>
                              {selectedRoleRules[0]?.verbs?.join(", ")}
                            </Typography>
                          }
                        />
                      </ListItem>
                      <ListItem>
                        <ListItemText
                          primary={
                            <Typography sx={{ fontSize: 18 }}>
                              <strong>Api Groups: </strong>
                            </Typography>
                          }
                          secondary={
                            <Typography sx={{ fontSize: 18 }}>
                              {selectedRoleRules[0]?.apiGroups?.join(", ")}
                            </Typography>
                          }
                        />
                      </ListItem>
                      <ListItem>
                        <ListItemText
                          primary={
                            <Typography sx={{ fontSize: 18 }}>
                              <strong>Resources: </strong>
                            </Typography>
                          }
                          secondary={
                            <Typography sx={{ fontSize: 18 }}>
                              {selectedRoleRules[0]?.resources?.join(", ")}
                            </Typography>
                          }
                        />
                      </ListItem>
                      <ListItem>
                        <ListItemText
                          primary={
                            <Typography sx={{ fontSize: 18 }}>
                              <strong>Non Resource URLs: </strong>
                            </Typography>
                          }
                          secondary={
                            <Typography sx={{ fontSize: 18 }}>
                              {selectedRoleRules[0]?.nonResourceURLs?.join(
                                ", ",
                              )}
                            </Typography>
                          }
                        />
                      </ListItem>
                      <ListItem>
                        <ListItemText
                          primary={
                            <Typography sx={{ fontSize: 18 }}>
                              <strong>Resource Names: </strong>
                            </Typography>
                          }
                          secondary={
                            <Typography sx={{ fontSize: 18 }}>
                              {selectedRoleRules[0]?.resourceNames?.join(", ")}
                            </Typography>
                          }
                        />
                      </ListItem>
                    </List>
                  </Box>
                )}
              </Stack>
            </Stack>
          </>
        ) : (
          <Stack spacing={5}>
            <Typography
              variant="h6"
              sx={{
                fontSize: 18,
              }}
            >
              <strong>Clusters: </strong>
            </Typography>
            <Stack direction={"row"} spacing={2}>
              <TextField
                placeholder="Search Resources..."
                value={searchCluster}
                onChange={handleClusterSearchChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                variant="outlined"
              />
            </Stack>

            <TableContainer
              component={Paper}
              sx={{
                overflow: "auto",
                height: "100%",
              }}
              className={classes.root}
            >
              <Table
                sx={{
                  height: "fit-content",
                  "& th": {
                    background: "#233044",
                    color: "#fff",
                  },
                  "& td, & th": {
                    border: "1px solid #233044",
                    fontSize: "18px",
                  },
                }}
                size="large"
                aria-label="sticky table"
                className={classes.table}
              >
                <TableHead>
                  <TableRow>
                    <TableCell align="center">Name</TableCell>
                    <TableCell align="center">Region</TableCell>
                    <TableCell align="center">Group Name</TableCell>
                    <TableCell align="center">Type</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredClusters.map((resource) => (
                    <>
                      <TableRow key={resource.name}>
                        <TableCell align="left">
                          <Stack
                            direction={"row"}
                            spacing={5}
                            display="flex"
                            alignItems="center"
                          >
                            <Stack
                              direction={"row"}
                              spacing={2}
                              alignItems={"center"}
                              sx={{
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                setClusterId(resource?.azureId);
                                setSelectedCluster(resource);
                                fetchData(true, "all", resource?.azureId);
                              }}
                            >
                              <Box>
                                <GrServerCluster
                                  style={{
                                    marginRight: 8,
                                    fontSize: "18px",
                                  }}
                                />
                              </Box>
                              <Box ml={2}>
                                <Typography
                                  variant="h6"
                                  sx={{
                                    fontSize: 18,
                                  }}
                                >
                                  {resource.name}
                                </Typography>
                                <Typography
                                  variant="body2"
                                  sx={{
                                    maxWidth: "350px",
                                    wordWrap: "break-word",
                                    whiteSpace: "normal",
                                  }}
                                >
                                  Region : {resource.regionName || "-"}
                                </Typography>
                              </Box>
                            </Stack>
                          </Stack>
                        </TableCell>
                        <TableCell align="center">
                          {resource.regionName || "-"}
                        </TableCell>
                        <TableCell align="center">
                          {resource.resourceGroupName || "-"}
                        </TableCell>
                        <TableCell align="center">
                          {resource.type || "-"}
                        </TableCell>
                      </TableRow>
                    </>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Stack>
        )}
      </Box>
    </>
  );
};

export default K8Roles;
