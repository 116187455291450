import create from "zustand";

const useNotificationStore = create((set) => ({
  adminNotifications: [],
  adminawsNotifications: [],
  adminazureNotifications: [],
  admingcpNotifications: [],
  adminweburlNotifications: [],

  setAdminNotifications: (notifications) => {
    set(() => ({
      adminNotifications: notifications,
    }));
  },

  setAdminAWSNotifications: (notifications) => {
    set(() => ({
      adminawsNotifications: notifications,
    }));
  },
  setAdminAzureNotifications: (notifications) => {
    set(() => ({
      adminazureNotifications: notifications,
    }));
  },
  setAdminGcpNotifications: (notifications) => {
    set(() => ({
      admingcpNotifications: notifications,
    }));
  },

  setAdminWebUrlNotifications: (notifications) => {
    set(() => ({
      adminweburlNotifications: notifications,
    }));
  },

  userNotifications: [],
  setUserNotifications: (notifications) => {
    set(() => ({
      userNotifications: notifications,
    }));
  },
  userAWSNotifications: [],
  setUserAWSNotifications: (notifications) => {
    set(() => ({
      userAWSNotifications: notifications,
    }));
  },
  userAzureNotifications: [],
  setUserAzureNotifications: (notifications) => {
    set(() => ({
      userAzureNotifications: notifications,
    }));
  },
  userGcpNotifications: [],
  setUserGcpNotifications: (notifications) => {
    set(() => ({
      userGcpNotifications: notifications,
    }));
  },
  userWebUrlNotifications: [],
  setUserWebUrlNotifications: (notifications) => {
    set(() => ({
      userWebUrlNotifications: notifications,
    }));
  },
}));

export default useNotificationStore;
