import {
  CreditCard,
  Layout,
  Sliders,
  Settings,
  Italic,
  Mail,
  FileText,
  BookOpen,
  Info,
  Folder,
} from "react-feather";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import DownloadIcon from "@mui/icons-material/Download";
import InsertInvitationIcon from "@mui/icons-material/InsertInvitation";
import { FaAws } from "react-icons/fa6";
import { VscAzure } from "react-icons/vsc";
import { FaGoogle } from "react-icons/fa6";
import NetworkLockedIcon from "@mui/icons-material/NetworkLocked";
const pagesSection = [
  {
    href: "/dashboard",
    icon: Sliders,
    title: "Dashboard",
    children: [
      {
        href: "/dashboard/overview",
        title: "Overview",
      },
      {
        href: "/dashboard/lolbin",
        title: "LOLBin",
      },
      {
        href: "/dashboard/alerts",
        title: "Alerts",
      },
    ],
  },
  {
    href: "/incidents",
    icon: Italic,
    title: "Incidents",
    children: [
      {
        href: "/auditLogs",
        title: "Device Audit Logs",
      },
      /* {
        href: "/deviceDebugLogs",
        title: "Device Debug Logs",
      }, */
      {
        href: "/serverAuditLogs",
        title: "Platform Audit Logs",
      },
      /* {
        href: "/serverDebugLogs",
        title: "Server Debug Logs",
      }, */
    ],
  },
  {
    href: "/aws",
    icon: FaAws,
    title: "Aws",
    children: [
      {
        href: "/aws/users",
        title: "Users",
      },
      {
        href: "/aws/roles",
        title: "Roles",
      },
      {
        href: "/aws/groups",
        title: "Groups",
      },
      {
        href: "/aws/resources",
        title: "Resources",
      },
      /* {
        href: "/aws/resourceRequest",
        title: "Resource Request",
      }, */
    ],
  },
  {
    href: "/activeDirectory",
    icon: Folder,
    title: "Active Directory",
    children: [
      {
        href: "/activeDirectory/analytics",
        title: "Analytics",
      },
      {
        href: "/activeDirectory/computers",
        title: "Computers",
      },
      {
        href: "/activeDirectory/userMembership",
        title: "User Membership",
      },
      {
        href: "/activeDirectory/organizationUnit",
        title: "Organization Unit",
      },
    ],
  },
  {
    href: "/azure-directory",
    icon: VscAzure,
    title: "Azure Directory",
    children: [
      {
        href: "/azure-directory/devices",
        title: "Devices",
      },
      {
        href: "/azure-directory/azureusers",
        title: "Users",
      },
      {
        href: "/azure-directory/roles",
        title: "Roles",
      },
      {
        href: "/azure-directory/groups",
        title: "Groups",
      },
      {
        href: "/azure-directory/resources",
        title: "Resources",
      },
    ],
  },
  {
    href: "/gcp",
    icon: FaGoogle,
    title: "GCP",
    children: [
      {
        href: "/gcp/gcpusers",
        title: "Users",
      },
      {
        href: "/gcp/roles",
        title: "Roles",
      },

      {
        href: "/gcp/resources",
        title: "Resources",
      },
    ],
  },
  {
    href: "/endpoints",
    icon: CreditCard,
    title: "Endpoints",
  },
  {
    href: "/endpointGroups",
    icon: CreditCard,
    title: "Endpoint Groups",
  },
  {
    href: "/webUrls",
    icon: NetworkLockedIcon,
    title: "Web Urls",
  },
  {
    href: "/policyProfiles",
    icon: Layout,
    title: "Policy Profiles",
  },
  {
    href: "/resourceCategories",
    icon: BookOpen,
    title: "Resource Categories",
  },
  {
    href: "/resourceRequests",
    icon: Mail,
    title: "Resource Requests",
    badge: true,
  },
  {
    icon: InsertInvitationIcon,
    title: "User Management",
    children: [
      {
        href: "/userInvites",
        title: "Invite Users",
      },
      {
        href: "/adUsers",
        title: "Active Directory Users",
      },
      {
        href: "/registeredUsers",
        title: "Registered Users",
      },
      /*  {
        href: "/userRoles",
        title: "User Roles",
      }, */
    ],
  },
  {
    // href: "/tenantManagement",
    icon: SupervisorAccountIcon,
    title: "Tenant Management",
    children: [
      {
        href: "/tenantDetails",
        title: "Tenant Details",
      },
      {
        href: "/credentials",
        title: "Credentials",
      },
      {
        href: "/api-access",
        title: "Api access key",
      },

      {
        href: "/tenantSettings",
        title: "Settings",
      },
    ],
  },
  {
    href: "/account",
    icon: Settings,
    title: "Account",
  },
  {
    href: "/reports",
    icon: FileText,
    title: "Reports",
    children: [
      {
        href: "/reports/overProvisioning",
        title: "Over-provisioning",
      },
      // {
      //   href: "/historical-data",
      //   title: "Historical-data",
      //   children: [
      //     {
      //       href: "/reports/privilegeCreep",
      //       title: "Privilege creep",
      //     },
      //   ],
      // },
    ],
  },

  {
    href: "/downloads",
    icon: DownloadIcon,
    title: "Downloads",
  },
  {
    href: "/about",
    icon: Info,
    title: "About",
  },
];

const epamUserPagesSection = [
  {
    href: "/aws",
    icon: FaAws,
    title: "Aws",
    children: [
      {
        href: "/aws/users",
        title: "Users",
      },
    ],
  },

  {
    href: "/azure-directory",
    icon: VscAzure,
    title: "Azure Directory",
    children: [
      {
        href: "/azure-directory/azureusers",
        title: "Users",
      },
    ],
  },

  {
    href: "/gcp",
    icon: FaGoogle,
    title: "GCP",
    children: [
      {
        href: "/gcp/gcpusers",
        title: "Users",
      },
    ],
  },

  {
    href: "/endpoints",
    icon: CreditCard,
    title: "Endpoints",
  },

  {
    href: "/userResourceRequests",
    icon: Mail,
    title: "Resource Requests",
    badge: true,
  },
  {
    href: "/account",
    icon: Settings,
    title: "Account",
  },
  {
    href: "/downloads",
    icon: DownloadIcon,
    title: "Downloads",
  },
  {
    href: "/about",
    icon: Info,
    title: "About",
  },
];

const usersPagesSection = [
  {
    href: "/aws",
    icon: FaAws,
    title: "Aws",
    children: [
      {
        href: "/aws/users",
        title: "Users",
      },
    ],
  },

  {
    href: "/azure-directory",
    icon: VscAzure,
    title: "Azure Directory",
    children: [
      {
        href: "/azure-directory/azureusers",
        title: "Users",
      },
    ],
  },

  {
    href: "/gcp",
    icon: FaGoogle,
    title: "GCP",
    children: [
      {
        href: "/gcp/gcpusers",
        title: "Users",
      },
    ],
  },

  {
    href: "/endpoints",
    icon: CreditCard,
    title: "Endpoints",
  },
  {
    href: "/webUrls",
    icon: NetworkLockedIcon,
    title: "Web Urls",
  },
  {
    href: "/userResourceRequests",
    icon: Mail,
    title: "Resource Requests",
    badge: true,
  },
  {
    href: "/account",
    icon: Settings,
    title: "Account",
  },
  {
    href: "/downloads",
    icon: DownloadIcon,
    title: "Downloads",
  },
  {
    href: "/about",
    icon: Info,
    title: "About",
  },
];
const epamAdminSection = [
  {
    href: "/dashboard",
    icon: Sliders,
    title: "Dashboard",
    children: [
      {
        href: "/dashboard/overview",
        title: "Overview",
      },
      {
        href: "/dashboard/lolbin",
        title: "LOLBin",
      },
      {
        href: "/dashboard/alerts",
        title: "Alerts",
      },
    ],
  },
  {
    href: "/incidents",
    icon: Italic,
    title: "Incidents",
    children: [
      {
        href: "/auditLogs",
        title: "Device Audit Logs",
      },
      /* {
        href: "/deviceDebugLogs",
        title: "Device Debug Logs",
      }, */
      {
        href: "/serverAuditLogs",
        title: "Platform Audit Logs",
      },
      /* {
        href: "/serverDebugLogs",
        title: "Server Debug Logs",
      }, */
    ],
  },
  {
    href: "/activeDirectory",
    icon: Folder,
    title: "Active Directory",
    children: [
      {
        href: "/activeDirectory/analytics",
        title: "Analytics",
      },
      {
        href: "/activeDirectory/computers",
        title: "Computers",
      },
      {
        href: "/activeDirectory/userMembership",
        title: "User Membership",
      },
      {
        href: "/activeDirectory/organizationUnit",
        title: "Organization Unit",
      },
    ],
  },

  {
    href: "/endpoints",
    icon: CreditCard,
    title: "Endpoints",
  },
  {
    href: "/endpointGroups",
    icon: CreditCard,
    title: "Endpoint Groups",
  },

  {
    href: "/policyProfiles",
    icon: Layout,
    title: "Policy Profiles",
  },
  {
    href: "/resourceCategories",
    icon: BookOpen,
    title: "Resource Categories",
  },
  {
    href: "/resourceRequests",
    icon: Mail,
    title: "Resource Requests",
    badge: true,
  },
  {
    icon: InsertInvitationIcon,
    title: "User Management",
    children: [
      {
        href: "/userInvites",
        title: "Invite Users",
      },
      {
        href: "/adUsers",
        title: "Active Directory Users",
      },
      {
        href: "/registeredUsers",
        title: "Registered Users",
      },
      /* {
        href: "/userRoles",
        title: "User Roles",
      }, */
    ],
  },
  {
    // href: "/tenantManagement",
    icon: SupervisorAccountIcon,
    title: "Tenant Management",
    children: [
      {
        href: "/tenantDetails",
        title: "Tenant Details",
      },
      {
        href: "/credentials",
        title: "Credentials",
      },
      {
        href: "/api-access",
        title: "Api access key",
      },
    ],
  },
  {
    href: "/account",
    icon: Settings,
    title: "Account",
  },
  {
    href: "/reports",
    icon: FileText,
    title: "Reports",
    children: [
      {
        href: "/reports/overProvisioning",
        title: "Over-provisioning",
      },
    ],
  },

  {
    href: "/downloads",
    icon: DownloadIcon,
    title: "Downloads",
  },
  {
    href: "/about",
    icon: Info,
    title: "About",
  },
];

const serverPamSection = [
  {
    href: "/dashboard",
    icon: Sliders,
    title: "Dashboard",
    children: [
      {
        href: "/dashboard/overview",
        title: "Overview",
      },
      {
        href: "/dashboard/lolbin",
        title: "LOLBin",
      },
      {
        href: "/dashboard/alerts",
        title: "Alerts",
      },
    ],
  },
  {
    href: "/incidents",
    icon: Italic,
    title: "Incidents",
    children: [
      {
        href: "/auditLogs",
        title: "Device Audit Logs",
      },
      /*  {
        href: "/deviceDebugLogs",
        title: "Device Debug Logs",
      }, */
      {
        href: "/serverAuditLogs",
        title: "Platform Audit Logs",
      },
      /* {
        href: "/serverDebugLogs",
        title: "Server Debug Logs",
      }, */
    ],
  },
  {
    href: "/aws",
    icon: FaAws,
    title: "Aws",
    children: [
      {
        href: "/aws/users",
        title: "Users",
      },
      {
        href: "/aws/roles",
        title: "Roles",
      },
      {
        href: "/aws/groups",
        title: "Groups",
      },
      {
        href: "/aws/resources",
        title: "Resources",
      },
      /* {
        href: "/aws/resourceRequest",
        title: "Resource Request",
      }, */
    ],
  },
  {
    href: "/azure-directory",
    icon: VscAzure,
    title: "Azure Directory",
    children: [
      {
        href: "/azure-directory/devices",
        title: "Devices",
      },
      {
        href: "/azure-directory/azureusers",
        title: "Users",
      },
      {
        href: "/azure-directory/roles",
        title: "Roles",
      },
      {
        href: "/azure-directory/groups",
        title: "Groups",
      },
      {
        href: "/azure-directory/resources",
        title: "Resources",
      },
    ],
  },
  {
    href: "/gcp",
    icon: FaGoogle,
    title: "GCP",
    children: [
      {
        href: "/gcp/gcpusers",
        title: "Users",
      },
      {
        href: "/gcp/roles",
        title: "Roles",
      },

      {
        href: "/gcp/resources",
        title: "Resources",
      },
    ],
  },
  {
    href: "/endpoints",
    icon: CreditCard,
    title: "Endpoints",
  },
  {
    href: "/endpointGroups",
    icon: CreditCard,
    title: "Endpoint Groups",
  },
  {
    href: "/webUrls",
    icon: NetworkLockedIcon,
    title: "Web Urls",
  },
  {
    href: "/policyProfiles",
    icon: Layout,
    title: "Policy Profiles",
  },
  {
    href: "/resourceCategories",
    icon: BookOpen,
    title: "Resource Categories",
  },
  {
    href: "/resourceRequests",
    icon: Mail,
    title: "Resource Requests",
    badge: true,
  },
  {
    icon: InsertInvitationIcon,
    title: "User Management",
    children: [
      {
        href: "/userInvites",
        title: "Invite Users",
      },
      /*   {
        href: "/registeredUsers",
        title: "Registered Users",
      }, */
      {
        href: "/registeredUsers",
        title: "Registered Users",
      },
    ],
  },
  {
    // href: "/tenantManagement",
    icon: SupervisorAccountIcon,
    title: "Tenant Management",
    children: [
      {
        href: "/tenantDetails",
        title: "Tenant Details",
      },
      {
        href: "/credentials",
        title: "Credentials",
      },
      {
        href: "/api-access",
        title: "Api access key",
      },
    ],
  },
  {
    href: "/account",
    icon: Settings,
    title: "Account",
  },
  {
    href: "/reports",
    icon: FileText,
    title: "Reports",
    children: [
      {
        href: "/reports/overProvisioning",
        title: "Over-provisioning",
      },
    ],
  },

  {
    href: "/downloads",
    icon: DownloadIcon,
    title: "Downloads",
  },
  {
    href: "/about",
    icon: Info,
    title: "About",
  },
];

export const adminNavsItems = [
  {
    title: "",
    pages: [...pagesSection],
  },
];
export const sysAdminNavsItems = [
  {
    title: "",
    pages: [...pagesSection],
  },
];

export const userNavsItems = [
  {
    title: "",
    pages: usersPagesSection,
  },
];

export const epamNavsItems = [
  {
    title: "",
    pages: [...epamAdminSection],
  },
];
export const serverPamNavsItems = [
  {
    title: "",
    pages: [...serverPamSection],
  },
];
export const epamUserNavsItems = [
  {
    title: "",
    pages: [...epamUserPagesSection],
  },
];
