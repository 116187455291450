import {
  Alert,
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputAdornment,
  Paper,
  Snackbar,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import useUserStore from "../../../services/userStore";
import { myLocalStorage } from "../../../components/StorageHelper";
import axios from "axios";
import { NGROK } from "../../../APIs";
import moment from "moment";
import { awsIcons } from "../AWS/AWSIcons";
import SearchIcon from "@mui/icons-material/Search";

const GcpUsers = () => {
  const [usersData, setUsersData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const { role, userId, email } = useUserStore((state) => state.user);
  const navigate = useNavigate();
  const [selectedTenantName, setSelectedTenantName] = useState(null);
  const [tenantList, setTenantList] = useState([]);
  const userData = useUserStore((state) => state.user);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState("success");
  const [alertMessage, setAlertMessage] = useState("");
  const [isSyncing, setIsSyncing] = useState(false);
  const [projectId, setProjectId] = useState(null);
  const [isGCPUserConfigured, setIsGCPUserConfigured] = useState(false);
  const [configureAccountModalOpen, setConfigureAccountModalOpen] =
    useState(false);
  const [isGCPConfigurationLoading, setIsGCPConfigurationLoading] =
    useState(false);

  const [gcpUsername, setGcpUsername] = useState("");
  const [gcpAccountemail, setGcpAccountEmail] = useState("");
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);
  const [userLoading, setUserLoading] = useState(false);
  const latestTenant = myLocalStorage.getItem("latestTenant")?.tenantName;

  const ondemandButton = async () => {
    setIsSyncing(true);
    const response = await axios.get(`${NGROK}/api/gcp-sync/manual`, {
      params: {
        tenantName: selectedTenantName,
      },
    });
    setIsSyncing(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "gcpUserName") {
      setGcpUsername(value);
    } else if (name === "gcpAccountEmail") {
      setGcpAccountEmail(value);
    }

    if (gcpUsername && gcpAccountemail) {
      setIsButtonEnabled(true);
    } else {
      setIsButtonEnabled(false);
    }
  };

  const getAllUsers = async () => {
    const response = await axios.get(
      `${NGROK}/api/gcp/users?tenanatName=${latestTenant}&projectId=${projectId} `,
    );
    const flattenedData = Object.values(response.data).flatMap((item) => item);

    if (response?.status === 200) return flattenedData;
    else return [];
  };

  const getAllGroups = async () => {
    const response = await axios.get(
      `${NGROK}/api/gcp/groups?tenantName=${latestTenant}`,
    );
    if (response?.status === 200) return response.data;
    else return [];
  };
  const getAllUsersWithRoles = async () => {
    const response = await axios.get(
      `${NGROK}/api/gcp/get-all-user-roles?tenantName=${latestTenant}&projectId=${projectId} `,
    );
    if (response?.status === 200) return response.data;
    else return [];
  };

  const combineUserAndRoles = (users, userRoles) => {
    return users.map((user) => {
      const emailRoles = userRoles[user?.email] || [];
      return {
        ...user,
        roles: emailRoles,
      };
    });
  };

  const fetchData = async (isFirst = false) => {
    try {
      if (isFirst) setLoading(true);
      const [users, groups, userRoles] = await Promise.all([
        getAllUsers(),
        getAllGroups(),
        getAllUsersWithRoles(),
      ]);

      const consolidatedUserAndRoles = combineUserAndRoles(users, userRoles);

      const combineData = {
        Users: consolidatedUserAndRoles,
        Groups: groups,
      };
      setUsersData(combineData);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data", error);
      setLoading(false);
      setUsersData(null);
    }
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };
  const handleTenantChange = (value) => {
    const tenant = tenantList.find((tenant) => tenant.tenantName === value);
    if (tenant !== undefined) {
      setSelectedTenantName(tenant.tenantName);
      myLocalStorage.setItem("latestTenant", tenant);
    }
  };

  const MAX_ITEMS_DISPLAYED = 2;

  const renderItemsWithMoreLink = (items, maxItems, user, groups) => {
    if (items?.length > 0) {
      if (items?.length <= maxItems) {
        return items?.map((item) => item?.roleName).join(", ");
      } else {
        const displayedItems = items
          .slice(0, maxItems)
          .map((item) => item?.roleName)
          .join(", ");
        const remainingCount = items?.length - maxItems;
        return (
          <>
            {displayedItems},{" "}
            <Link
              to={`/gcp/gcpusers/userDetails`}
              state={{ user, groups, roles: items, isconfigured: false }}
            >
              {remainingCount} more
            </Link>
          </>
        );
      }
    } else {
      <Typography variant="body2">-</Typography>;
    }
  };

  const validateAndConfigureTheGCPUser = async () => {
    try {
      const response = await axios.post(
        `${NGROK}/api/gcp/addGcpUserConfig?tenantName=${latestTenant}&tenantEmail=${email}&gcpUserName=${gcpUsername}&gcpAccountEmail=${gcpAccountemail}&projectId=${projectId}`,
      );
      if (response?.data) {
        setAlertSeverity("success");
        setAlertMessage("GCP User successfully configured!");
        setOpenSnackbar(true);
        fetchGCPUserConfiguration(true);
        setConfigureAccountModalOpen(false);
      }
    } catch (ex) {
      setAlertSeverity("error");
      setAlertMessage("Failed to configure GCP user. Please try again.");
      setOpenSnackbar(true);
      console.error(ex);
      setConfigureAccountModalOpen(false);
    }
  };

  const fetchGCPUserConfiguration = async (isInital = false) => {
    if (!projectId) return;
    try {
      setUserLoading(true);
      const response = await axios.get(
        `${NGROK}/api/gcp/getGcpUserConfig?tenantName=${latestTenant}&tenantEmail=${email}&projectId=${projectId}`,
      );
      if (response?.data) {
        let { gcpUserName, gcpAccountEmail } = response?.data;
        console.log(gcpAccountEmail, gcpUserName);
        if (gcpAccountEmail === null && gcpUserName === null) {
          setIsGCPUserConfigured(false);
          setConfigureAccountModalOpen(true);
          setUserLoading(false);
        } else {
          setIsGCPUserConfigured(false);
          fetchGCPConfigureDetails(gcpUserName, gcpAccountEmail, isInital);
        }
      } else {
        setUserLoading(false);
        setIsGCPUserConfigured(false);
        setConfigureAccountModalOpen(true);
      }
    } catch (e) {
      setUserLoading(false);
      setIsGCPUserConfigured(true);
      setConfigureAccountModalOpen(true);
    }
  };

  const getSpecificUsers = async (gcpUserName, gcpAccountEmail) => {
    let projectId = myLocalStorage.getItem("gcpSelectedProjectId");

    const response = await axios.get(
      `${NGROK}/api/gcp/fetch-users-details?projectId=${projectId}&tenanatName=${latestTenant}&gcpUserEmail=${gcpAccountEmail}`,
    );
    return response.data;
  };
  const fetchGCPConfigureDetails = async (
    gcpUserName,
    gcpAccountEmail,
    isInital,
  ) => {
    try {
      const [users] = await Promise.all([
        getSpecificUsers(gcpUserName, gcpAccountEmail),
      ]);
      const specificUserdata = users;
      setIsGCPConfigurationLoading(false);
      navigate("/gcp/gcpusers/userDetails", {
        state: {
          user: specificUserdata,
          groups: specificUserdata.groups || [],
          roles: specificUserdata.roles || [],
          isconfigured: isInital,
        },
      });
      setLoading(false);
      setUserLoading(false);
    } catch (error) {
      console.error("Error fetching data", error);
      setLoading(false);
      setUserLoading(false);
    }
  };

  useEffect(() => {
    if (role !== "TENANT_USER" && projectId != null) {
      fetchData(true);
      const interval = setInterval(() => {
        fetchData();
      }, 10000);
      return () => clearInterval(interval);
    } else {
      fetchGCPUserConfiguration();
    }
  }, [latestTenant, projectId]);

  useEffect(() => {
    if (tenantList.length === 1)
      return setSelectedTenantName(tenantList[0].tenantName);

    if (tenantList?.length > 1) {
      const latestTenantName =
        myLocalStorage.getItem("latestTenant")?.tenantName;

      const tenant = tenantList.find(
        (tenant) => tenant.tenantName === latestTenantName,
      );
      if (tenant) setSelectedTenantName(tenant.tenantName);
      else setSelectedTenantName(tenantList[0].tenantName);
    }
  }, [tenantList]);

  const fetchGCPProjectId = async () => {
    const response = await axios.get(`${NGROK}/api/gcp/auth/configuration`, {
      params: {
        tenantName: latestTenant,
      },
    });
    if (response.data && response?.data?.length !== 0) {
      setProjectId(response.data[0].projectId);
      myLocalStorage.setItem(
        "gcpSelectedProjectId",
        response.data[0].projectId,
      );
    } else {
      setProjectId(null);
      myLocalStorage.removeItem("gcpSelectedProjectId");
    }
  };

  useEffect(() => {
    const fetchTenants = async () => {
      const response = await axios.get(`${NGROK}/api/get-all-domains`);
      setTenantList(response.data);
    };

    fetchTenants();
  }, [userData]);
  useEffect(() => {
    fetchGCPProjectId();
  }, [latestTenant]);

  const filteredUsers = usersData
    ? usersData.Users.filter((user) =>
        user.userName.toLowerCase().includes(searchTerm.toLowerCase()),
      )
    : [];

  useEffect(() => {
    if (gcpUsername.trim() && gcpAccountemail.trim()) {
      setIsButtonEnabled(true);
    } else {
      setIsButtonEnabled(false);
    }
  }, [gcpUsername, gcpAccountemail]);

  return (
    <Box>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={() => setOpenSnackbar(false)}
          severity={alertSeverity}
          sx={{ width: "100%" }}
        >
          {alertMessage}
        </Alert>
      </Snackbar>

      {role !== "TENANT_USER" ? (
        <>
          <Stack spacing={5}>
            <Box display="flex" justifyContent="flex-start">
              <Stack
                direction={"row"}
                spacing={2}
                sx={{
                  width: "100%",
                  justifyContent: "space-between",
                }}
              >
                <Stack direction={"row"} spacing={2}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "15px",
                    }}
                  >
                    {tenantList?.length > 0 ? (
                      <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        value={selectedTenantName ? selectedTenantName : " "}
                        options={tenantList.map((tenant) => tenant.tenantName)}
                        sx={{ width: 300 }}
                        renderInput={(params) => (
                          <TextField {...params} label="Tenant List" />
                        )}
                        onChange={(e, value) => {
                          handleTenantChange(value);
                        }}
                      />
                    ) : null}
                  </Box>
                  <TextField
                    placeholder="Search users..."
                    value={searchTerm}
                    onChange={handleSearchChange}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Stack>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <Button
                    variant="outlined"
                    color="primary"
                    sx={{ ml: "auto" }}
                    onClick={ondemandButton}
                    disabled={isSyncing}
                  >
                    {isSyncing ? "Syncing" : "Sync"}
                  </Button>
                </Box>
              </Stack>
            </Box>
            {loading && role !== "TENANT_USER" ? (
              <Box display={"flex"} p={5}>
                <CircularProgress />
              </Box>
            ) : filteredUsers.length === 0 ? (
              <Typography
                variant="body2"
                color="textSecondary"
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
              >
                No Users available
              </Typography>
            ) : (
              <TableContainer
                style={{ height: "fit-content" }}
                component={Paper}
              >
                <Table
                  sx={{
                    height: "fit-content",
                    maxWidth: "100%",
                    "& th": {
                      background: "#233044",
                      color: "#fff",
                    },
                    "& td, & th": {
                      border: "1px solid #233044",
                      fontSize: "16px",
                    },
                  }}
                  size="large"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent={"center"}
                        >
                          {awsIcons.user}
                          <Box ml={2}>
                            <Typography variant="h6">User Name</Typography>
                          </Box>
                        </Box>
                      </TableCell>

                      <TableCell align="center">
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent={"center"}
                        >
                          {awsIcons.roles}
                          <Box ml={2}>
                            <Typography variant="h6">Roles</Typography>
                          </Box>
                        </Box>
                      </TableCell>

                      <TableCell align="center">
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent={"center"}
                        >
                          <Box ml={2}>
                            <Typography variant="h6">Synced At</Typography>
                          </Box>
                        </Box>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredUsers.map((user) => (
                      <TableRow key={user.azureId}>
                        <TableCell align="center">
                          <Link
                            to={`/gcp/gcpusers/userDetails`}
                            state={{
                              user,
                              groups: user.groups,
                              roles: user.roles,
                              isconfigured: false,
                            }}
                          >
                            {user.userName}
                          </Link>
                        </TableCell>

                        <TableCell align="center">
                          {user?.roles?.length > 0
                            ? renderItemsWithMoreLink(
                                user.roles,
                                MAX_ITEMS_DISPLAYED,
                                user,
                                user.groups,
                              )
                            : "-"}
                        </TableCell>

                        <TableCell align="center">
                          <Typography variant="h6">
                            {moment(user.syncedAt).format(
                              "DD/MM/YYYY HH:mm:ss",
                            )}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </Stack>
        </>
      ) : (
        <>
          {userLoading ? (
            <>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "65vh",
                }}
              >
                <CircularProgress />
              </Box>
            </>
          ) : !isGCPUserConfigured ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "65vh",
              }}
            >
              <Button
                variant="outlined"
                color="primary"
                onClick={() => setConfigureAccountModalOpen(true)}
              >
                Configure GCP account
              </Button>
              <Dialog
                open={configureAccountModalOpen}
                onClose={() => {
                  setConfigureAccountModalOpen(false);
                }}
              >
                <DialogTitle>GCP User Configuration</DialogTitle>
                <DialogContent>
                  <TextField
                    margin="dense"
                    label="GCP User Name"
                    name="gcpUserName"
                    fullWidth
                    variant="outlined"
                    value={gcpUsername}
                    onChange={handleInputChange}
                  />
                  <TextField
                    autoFocus
                    margin="dense"
                    label="GCP Account Email"
                    name="gcpAccountEmail"
                    fullWidth
                    variant="outlined"
                    value={gcpAccountemail}
                    onChange={handleInputChange}
                  />
                </DialogContent>
                <DialogActions>
                  <Button onClick={() => setConfigureAccountModalOpen(false)}>
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    disabled={!isButtonEnabled}
                    onClick={() => {
                      validateAndConfigureTheGCPUser();
                    }}
                  >
                    Validate
                  </Button>
                </DialogActions>
              </Dialog>
            </Box>
          ) : isGCPConfigurationLoading ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "65vh",
              }}
            >
              <CircularProgress />
            </Box>
          ) : null}
        </>
      )}
    </Box>
  );
};

export default GcpUsers;
