import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import styled from "styled-components";
import { Outlet, useLocation } from "react-router-dom";

import { Box, CssBaseline, Paper as MuiPaper } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { spacing } from "@mui/system";

import GlobalStyle from "../components/GlobalStyle";
import Navbar from "../components/navbar/Navbar";
import {
  userNavsItems,
  adminNavsItems,
  sysAdminNavsItems,
  epamNavsItems,
  serverPamNavsItems,
  epamUserNavsItems,
} from "../components/sidebar/dashboardItems";
import Sidebar from "../components/sidebar/Sidebar";

import useUserStore from "../services/userStore";
import { NGROK } from "../APIs";
import BreadcrumbsNavProfiles from "../components/BreadcrumbsNavProfiles";
import BreadcrumbsNavEndpoints from "../components/BreadcrumbsNavEndpoints";
import useNotificationsStore from "../services/notificationStore";
import IdleTimer from "../components/IdleTimer/IdleTimer";
import AWSBreadCrumpUsers from "../pages/pages/AWS/components/AWSBreadCrumpUsers";
import { myLocalStorage } from "../components/StorageHelper";
import UrlFetchManager from "../services/UrlFetchManager";

const drawerWidth = 258;

const Root = styled.div`
  display: flex;
  min-height: 100vh;
`;

const Drawer = styled.div`
  ${(props) => props.theme.breakpoints.up("md")} {
    width: ${drawerWidth}px;
    flex-shrink: 0;
  }
`;

const AppContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  max-width: 100%;
`;

const Paper = styled(MuiPaper)(spacing);

const MainContent = styled(Paper)`
  flex: 1;
  background: ${(props) => props.theme.palette.background.default};

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    flex: none;
  }

  .MuiPaper-root .MuiPaper-root {
    box-shadow: none;
  }
`;

const Dashboard = ({ children }) => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const [userAccessMenu, setUserAccessMenu] = useState(null);

  const location = useLocation();
  const {
    setAdminNotifications,
    setUserNotifications,
    setAdminAWSNotifications,
    setAdminAzureNotifications,
    setUserAWSNotifications,
    setUserAzureNotifications,
    setUserGcpNotifications,
    setAdminGcpNotifications,
    setAdminWebUrlNotifications,
    setUserWebUrlNotifications,
  } = useNotificationsStore();

  const userData = useUserStore((state) => state.user);
  const activeComputer = useUserStore((state) => state.activeComputer);

  const latestComputer =
    activeComputer || myLocalStorage.getItem("activeComputer");
  const lastSession = useUserStore((state) => state.lastSession);

  const latestTenant = myLocalStorage.getItem("latestTenant")?.tenantName;
  let projectId = myLocalStorage.getItem("gcpSelectedProjectId") || null;

  let userAccess = userData.role;
  let userRole = !userData.userRole ? "" : userData.userRole;

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const theme = useTheme();
  const isLgUp = useMediaQuery(theme.breakpoints.up("lg"));

  useEffect(() => {
    if (userRole === "epam-user") {
      setUserAccessMenu(epamUserNavsItems);
    } else if (userRole === "epam-admin") {
      setUserAccessMenu(epamNavsItems);
    } else if (userRole === "server-pam-admin") {
      setUserAccessMenu(serverPamNavsItems);
    } else if (userAccess === "TENANT_ADMIN") {
      setUserAccessMenu(adminNavsItems);
    } else if (userAccess === "SYS_ADMIN") {
      setUserAccessMenu(sysAdminNavsItems);
    } else if (userAccess === "TENANT_USER") {
      setUserAccessMenu(userNavsItems);
    }
  }, [userAccess, userRole]);

  const fetchNotifications = useCallback(async () => {
    const adminController = new AbortController();
    const userController = new AbortController();
    try {
      if (latestTenant) {
        if (userAccess === "TENANT_ADMIN" || userAccess === "SYS_ADMIN") {
          if (userData?.email && latestTenant) {
            const response = await axios.get(
              `${NGROK}/api/access/admin-notifications?email=${userData.email}&tenantName=${latestTenant}`,
              { signal: adminController.signal },
            );
            setAdminNotifications(response.data);
          }
          const adminresponse = await axios.get(
            `${NGROK}/api/aws-request-access?tenantName=${latestTenant}`,
            { signal: adminController.signal },
          );
          setAdminAWSNotifications(adminresponse.data);
          try {
            const adminAzureresponse = await UrlFetchManager(
              `${NGROK}/api/azureResources/v1/requests/all?tenantName=${latestTenant}`,
              { signal: adminController.signal },
            );
            setAdminAzureNotifications(adminAzureresponse.data);
          } catch (e) {
            console.error(e);
          }
          try {
            if (projectId != null) {
              const gcpadminresponse = await axios.get(
                `${NGROK}/api/gcp/resource/gcp-request-access?tenantName=${latestTenant}&projectId=${projectId}`,
                { signal: userController.signal },
              );
              setAdminGcpNotifications(gcpadminresponse.data);
            }
          } catch (e) {
            console.error(e);
          }
          try {
            const webUrlResponse = await axios.get(
              `${NGROK}/api/websites/weburl-request-access?tenantName=${latestTenant}`,
              { signal: userController.signal },
            );
            setAdminWebUrlNotifications(webUrlResponse?.data || []);
          } catch (e) {
            console.error(e);
          }
        } else {
          if (userData?.email && latestTenant) {
            const response = await axios.get(
              `${NGROK}/api/access/user-notifications-by-email?email=${userData.email}&tenantName=${latestTenant}`,
              { signal: userController.signal },
            );
            setUserNotifications(response.data);
          }
          const adminresponse = await axios.get(
            `${NGROK}/api/aws-request-access?tenantName=${latestTenant}&email=${userData.email}`,
            { signal: userController.signal },
          );
          setUserAWSNotifications(adminresponse.data);

          try {
            const adminAzureresponse = await axios.get(
              `${NGROK}/api/azureResources/v1/requests/all?tenantName=${latestTenant}&email=${userData.email}`,
              { signal: userController.signal },
            );
            setUserAzureNotifications(adminAzureresponse.data);
          } catch (e) {
            console.error(e);
          }
          try {
            if (projectId != null) {
              const gcpuserresponse = await axios.get(
                `${NGROK}/api/gcp/resource/gcp-request-access?tenantName=${latestTenant}&email=${userData.email}&projectId=${projectId}`,
                { signal: userController.signal },
              );
              setUserGcpNotifications(gcpuserresponse.data);
            }
          } catch (e) {
            console.error(e);
          }
          try {
            const webUrlResponse = await axios.get(
              `${NGROK}/api/websites/weburl-request-access?tenantName=${latestTenant}&email=${userData.email}`,
              { signal: userController.signal },
            );
            setUserWebUrlNotifications(webUrlResponse?.data || []);
          } catch (e) {
            console.error(e);
          }
        }
      }
    } catch (error) {
      console.error("Error fetching notifications:", error);
    }
  }, [
    userAccess,
    userData.email,
    latestComputer,
    latestTenant,
    location.pathname,
  ]);

  const fetchGCPProjectId = async () => {
    if (latestTenant != null && latestTenant != undefined) {
      const response = await axios.get(`${NGROK}/api/gcp/auth/configuration`, {
        params: {
          tenantName: latestTenant,
        },
      });
      if (response.data && response?.data?.length !== 0) {
        projectId = response.data[0].projectId;
        myLocalStorage.setItem(
          "gcpSelectedProjectId",
          response.data[0].projectId,
        );
      } else {
        myLocalStorage.removeItem("gcpSelectedProjectId");
      }
    }
  };

  useEffect(() => {
    const adminController = new AbortController();
    const userController = new AbortController();
    if (userData?.email) {
      fetchNotifications();
      fetchGCPProjectId();
      const interval = setInterval(() => {
        fetchNotifications();
      }, 5000);

      return () => {
        clearInterval(interval);
        adminController.abort();
        userController.abort();
      };
    }
  }, [userData.email, latestTenant]);

  return (
    <Root>
      <CssBaseline />
      <GlobalStyle />
      <Drawer>
        <Box sx={{ display: { xs: "block", lg: "none" } }}>
          <Sidebar
            PaperProps={{ style: { width: drawerWidth } }}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            items={userAccessMenu}
          />
        </Box>
        <Box sx={{ display: { xs: "none", md: "block" } }}>
          <Sidebar
            PaperProps={{ style: { width: drawerWidth } }}
            items={userAccessMenu}
          />
        </Box>
      </Drawer>
      <AppContent>
        <Navbar onDrawerToggle={handleDrawerToggle} />
        <MainContent p={isLgUp ? 8 : 4} mt={-5}>
          {location.pathname.startsWith("/endpoints") ? (
            <Box p={4}>
              <BreadcrumbsNavEndpoints />
            </Box>
          ) : location.pathname.startsWith("/policyProfiles") ? (
            <Box p={4}>
              <BreadcrumbsNavProfiles />
            </Box>
          ) : location.pathname.startsWith("/aws") ? (
            <Box p={4}>
              <AWSBreadCrumpUsers path={"users"} />
            </Box>
          ) : location.pathname.startsWith("/azure-directory") ? (
            <Box p={4}>
              <AWSBreadCrumpUsers path={"azureusers"} />
            </Box>
          ) : null}
          <IdleTimer />

          {children}
          <Outlet />
        </MainContent>
      </AppContent>
    </Root>
  );
};

export default Dashboard;
