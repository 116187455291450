import React, { useEffect, useState } from "react";
import useUserStore from "../../../../services/userStore";
import { myLocalStorage } from "../../../../components/StorageHelper";
import {
  Button,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  List,
  ListItem,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import { NGROK } from "../../../../APIs";

const GCPRequestResourceAccessForm = ({
  data,
  from,
  open,
  onClose,
  title,
  projectId,
  gcpUserEmail,
  resource,
}) => {
  const userData = useUserStore((state) => state.user);
  const [publishedRoles, setPublishedRoles] = useState([]);
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [step, setStep] = useState(1);
  const [requestMessage, setRequestMessage] = useState("");
  const [selectedTime, setSelectedTime] = useState("");
  const [accessType, setAccessType] = useState("DYNAMIC");
  const [searchQuery, setSearchQuery] = useState(""); // For search filter

  const latestTenant = myLocalStorage.getItem("latestTenant")?.tenantName;

  const handleInputChange = (value) => {
    const regex = /^(?!0)[0-9\b]+$/;
    const stringValue = String(value);

    const valueAsNumber = parseInt(value);

    if (
      stringValue?.length > 3 ||
      stringValue.includes("e") ||
      stringValue === "0"
    )
      return;

    if (regex.test(stringValue) || !stringValue) {
      setSelectedTime(value);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "+" || event.key === "-") {
      event.preventDefault();
    }
  };

  const handleAccessType = (value) => {
    if (value === "PERMANENT") setSelectedTime("");
    setAccessType(value);
  };

  const handleClose = () => {
    setRequestMessage("");
    setSelectedTime("");
    setSelectedRoles([]);
    onClose();
  };

  const handlePolicySelect = (role) => {
    if (selectedRoles.includes(role)) {
      setSelectedRoles(selectedRoles.filter((p) => p !== role));
    } else {
      setSelectedRoles([...selectedRoles, role]);
    }
  };

  const fetchAllPublishedRoles = async () => {
    try {
      let projectId = myLocalStorage.getItem("gcpSelectedProjectId");
      const response = await axios.get(
        `${NGROK}/api/gcp/resource/getApplicableRoles?tenantName=${latestTenant}&projectId=${projectId}&resourceType=${resource}`,
      );
      if (response?.data) {
        setPublishedRoles(response.data);
      }
    } catch (err) {
      console.error(err);
    }
  };
  const filteredRoles = publishedRoles.filter((policy) =>
    policy.roleName.toLowerCase().includes(searchQuery.toLowerCase()),
  );
  const handleSubmit = async () => {
    const requestdata = {
      projectId: projectId,
      email: userData?.email,
      gcpUserEmail: gcpUserEmail,
      accessType: from,
      roles: selectedRoles,
      requestMessage,
      requestStatus: "PENDING",
      privilegeLevel: accessType,
      selectedTime: selectedTime,
      tenantName: latestTenant,
      accessName: title,
    };

    try {
      await axios.post(
        `${NGROK}/api/gcp/resource/gcp-request-access`,
        requestdata,
      );
      handleClose();
    } catch (error) {
      console.error("Error submitting request:", error);
    }
  };

  useEffect(() => {
    if (from === "console") setStep(2);
    fetchAllPublishedRoles();
  }, []);

  return (
    <>
      <Dialog open={open} onClose={handleClose} fullWidth>
        <DialogTitle sx={{ fontSize: "18px" }}>
          <strong>
            {step === 1 ? "Select Roles" : `Access request to ${title}`}
          </strong>
        </DialogTitle>
        <DialogContent>
          <Stack spacing={4} mt={2}>
            {step === 1 ? (
              <>
                <TextField
                  label="Search Roles"
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
                <List
                  sx={{
                    maxHeight: "450px",
                    overflowY: "auto",
                    marginTop: "16px",
                  }}
                >
                  {filteredRoles.length === 0 ? (
                    <>
                      <ListItem
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          width: "100%",
                        }}
                      >
                        <Typography variant="h6" align="center">
                          <strong>No roles available</strong>
                        </Typography>
                      </ListItem>
                      <ListItem
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          width: "100%",
                        }}
                      >
                        <Typography
                          variant="caption"
                          align="center"
                          color="textSecondary"
                        >
                          Publish role to list
                        </Typography>
                      </ListItem>
                    </>
                  ) : (
                    filteredRoles.map((role) => (
                      <ListItem key={role.roleId}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={selectedRoles.includes(role)}
                              onChange={() => handlePolicySelect(role)}
                            />
                          }
                          label={role.roleName}
                        />
                      </ListItem>
                    ))
                  )}
                </List>
              </>
            ) : (
              <>
                {from !== "console" ? (
                  <Stack spacing={2} mt={3}>
                    <Typography variant="h6">Selected Roles:</Typography>
                    <Stack direction="row" spacing={1} flexWrap="wrap">
                      {selectedRoles.map((role, index) => (
                        <Chip
                          key={index}
                          label={role.roleName}
                          variant="outlined"
                        />
                      ))}
                    </Stack>
                  </Stack>
                ) : null}

                <FormControl component="fieldset">
                  <Typography variant="h6">Access Type:</Typography>
                  <RadioGroup
                    aria-label="access-type"
                    name="access-type"
                    value={accessType}
                    onChange={(e) => handleAccessType(e.target.value)}
                    row
                  >
                    <FormControlLabel
                      value="PERMANENT"
                      control={<Radio />}
                      label="Permanent"
                    />
                    <FormControlLabel
                      value="DYNAMIC"
                      control={<Radio />}
                      label="Timed"
                    />
                  </RadioGroup>
                </FormControl>

                <Typography variant="h6">Resource Name: {title}</Typography>

                <Stack spacing={2} mt={3}>
                  <Typography variant="h6">Access justification:</Typography>
                  <TextField
                    maxRows={5}
                    minRows={5}
                    inputProps={{ maxLength: 150 }}
                    multiline
                    value={requestMessage}
                    onChange={(e) => setRequestMessage(e.target.value)}
                  />
                </Stack>

                {accessType === "DYNAMIC" && (
                  <Stack spacing={2}>
                    <Typography variant="h6">Time:</Typography>
                    <TextField
                      inputProps={{
                        inputMode: "numeric",
                        pattern: "[0-9]+",
                      }}
                      onKeyPress={handleKeyPress}
                      value={selectedTime}
                      type="text"
                      fullWidth
                      size="small"
                      label={"Enter time in minutes"}
                      onChange={(e) => {
                        const newValue = e.target.value;
                        handleInputChange(newValue);
                      }}
                      error={
                        from === "console" &&
                        selectedTime &&
                        parseInt(selectedTime) < 15
                      }
                      helperText={
                        from === "console" &&
                        selectedTime &&
                        parseInt(selectedTime) < 15
                          ? "Minimum time duration is 15 minutes"
                          : ""
                      }
                    />
                  </Stack>
                )}
              </>
            )}
          </Stack>
        </DialogContent>
        <DialogActions>
          {step === 1 ? (
            <Button
              onClick={() => setStep(2)}
              color="primary"
              disabled={selectedRoles.length === 0}
            >
              Next
            </Button>
          ) : (
            <>
              {from !== "console" ? (
                <Button onClick={() => setStep(1)} color="secondary">
                  Back
                </Button>
              ) : null}

              <Button onClick={handleClose} color="primary">
                Cancel
              </Button>
              <Button
                onClick={handleSubmit}
                color="primary"
                disabled={
                  from === "console"
                    ? !selectedTime || parseInt(selectedTime) < 15
                    : false
                }
              >
                Submit
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default GCPRequestResourceAccessForm;
