import React, { useEffect, useState } from "react";
import { Box, Tab, Tabs, Button, Stack } from "@mui/material";
import { TabContext, TabPanel } from "@mui/lab";
import DeviceOverViewDetails from "./DeviceOverViewDetails";
import DeviceAlertsDetails from "./DeviceAlertsDetails";
import DeviceLolBinDetails from "./DeviceLolBinDetails";
import DeviceResourcesDetails from "./DeviceResourcesDetails";
import DeviceAuditLogsDetails from "./DeviceAuditLogsDetails";
import DeviceSessionsDetails from "./DeviceSessionsDetails";
import useUserStore from "../../../../services/userStore";
import { useLocation } from "react-router-dom";
import DeviceConnectionsDetails from "./DeviceConnectionsDetails";
import { myLocalStorage } from "../../../../components/StorageHelper";
import DashboardIcon from "@mui/icons-material/Dashboard";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import RadioButtonCheckedOutlinedIcon from "@mui/icons-material/RadioButtonCheckedOutlined";
import MoveToInboxOutlinedIcon from "@mui/icons-material/MoveToInboxOutlined";
import {
  InsertDriveFile as ResourcesIcon,
  History as AuditLogsIcon,
  Laptop as SessionsIcon,
  Link as ConnectionsIcon,
} from "@mui/icons-material";
import DeviceResourceRequest from "./DeviceResourceRequest";
import { RiComputerLine } from "react-icons/ri";
import GatewayDevices from "./GatewayDevices";

const EndpointsDeviceDetails = () => {
  const location = useLocation();
  const lastSession = useUserStore((state) => state.lastSession);
  const activeComputer = useUserStore((state) => state.activeComputer);
  const latestComputer =
    activeComputer || myLocalStorage.getItem("activeComputer");

  const latestTenant =
    lastSession.latestTenant ||
    myLocalStorage.getItem("latestTenant")?.tenantName;

  const locationState = location?.state || {};
  const { device, selectedTenant } = locationState;

  const deviceDetails = device || latestComputer || {};
  const selectedTenantDetails = selectedTenant || latestTenant || null;

  const [detailsTab, setDetailsTab] = useState("0");
  const { role, userId } = useUserStore((state) => state.user);

  useEffect(() => {
    if (role !== "TENANT_USER") {
      if (deviceDetails?.isManuallyAddedMachine) {
        setDetailsTab("5");
      } else {
        setDetailsTab("0");
      }
    } else {
      setDetailsTab("3");
    }
  }, [role]);

  useEffect(() => {}, [device, location.state, selectedTenant]);

  return (
    <TabContext value={detailsTab}>
      <Box sx={{ position: "relative", padding: 2 }}>
        <Tabs
          value={detailsTab}
          onChange={(event, newValue) => setDetailsTab(newValue)}
          variant="scrollable"
          scrollButtons="auto"
        >
          {role !== "TENANT_USER" && !deviceDetails?.isManuallyAddedMachine ? (
            <Tab icon={<DashboardIcon />} label="Overview" value="0" />
          ) : null}

          {role !== "TENANT_USER" && !deviceDetails?.isManuallyAddedMachine ? (
            <Tab icon={<WarningAmberIcon />} label="Alerts" value="1" />
          ) : null}
          {role !== "TENANT_USER" && !deviceDetails?.isManuallyAddedMachine ? (
            <Tab icon={<VerifiedUserIcon />} label="LOLBin" value="2" />
          ) : null}
          {!deviceDetails?.isManuallyAddedMachine ? (
            <Tab icon={<ResourcesIcon />} label="Resources" value="3" />
          ) : null}

          {role !== "TENANT_USER" && !deviceDetails?.isManuallyAddedMachine ? (
            <Tab icon={<AuditLogsIcon />} label="Audit Logs" value="4" />
          ) : null}
          {role !== "TENANT_USER" ? (
            <Tab
              icon={<RadioButtonCheckedOutlinedIcon />}
              label="Recordings"
              value="5"
            />
          ) : null}
          {/*
          {role !== "TENANT_USER" && !deviceDetails?.isManuallyAddedMachine ? (
            <Tab icon={<SessionsIcon />} label="Sessions" value="6" />
          ) : null}
          */}
          {role !== "TENANT_USER" ? (
            <Tab
              icon={<MoveToInboxOutlinedIcon />}
              label="Resource Requests"
              value="7"
            />
          ) : null}
          {role !== "TENANT_USER" && deviceDetails?.isGateway ? (
            <Tab
              icon={<RiComputerLine size={25} />}
              label="Gateway devices"
              value="8"
            />
          ) : null}
        </Tabs>

        <TabPanel value="0">
          <DeviceOverViewDetails
            computerName={deviceDetails?.dNSHostName}
            selectedTenant={selectedTenantDetails?.tenantName}
          />
        </TabPanel>
        <TabPanel value="1">
          <DeviceAlertsDetails
            computerName={deviceDetails?.dNSHostName}
            selectedTenant={selectedTenantDetails?.tenantName}
          />
        </TabPanel>
        <TabPanel value="2">
          <DeviceLolBinDetails
            computerName={deviceDetails?.dNSHostName}
            selectedTenant={selectedTenantDetails?.tenantName}
          />
        </TabPanel>
        <TabPanel value="3">
          <DeviceResourcesDetails
            device={deviceDetails}
            selectedTenant={selectedTenantDetails?.tenantName}
          />
        </TabPanel>
        <TabPanel value="4">
          <DeviceAuditLogsDetails
            device={deviceDetails}
            selectedTenant={selectedTenantDetails?.tenantName}
          />
        </TabPanel>
        <TabPanel value="5">
          <DeviceConnectionsDetails device={deviceDetails} />
        </TabPanel>
        {/**
         * <TabPanel value="6">
          <DeviceSessionsDetails />
        </TabPanel>
         */}

        <TabPanel value="7">
          <DeviceResourceRequest device={deviceDetails} />
        </TabPanel>
        <TabPanel value="8">
          <GatewayDevices
            computerName={deviceDetails?.dNSHostName}
            selectedTenant={selectedTenantDetails?.tenantName}
            device={deviceDetails}
            latestComputer={latestComputer}
          />
        </TabPanel>
      </Box>
    </TabContext>
  );
};

export default EndpointsDeviceDetails;
