import {
  Alert,
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Snackbar,
  Stack,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import { myLocalStorage } from "../../../components/StorageHelper";
import axios from "axios";
import { NGROK } from "../../../APIs";
import useUserStore from "../../../services/userStore";
import AddURLModal from "./Components/AddURLModal";
import ConfiguredURLs from "./Components/ConfiguredURLs";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";

const WebUrls = () => {
  const userData = useUserStore((state) => state.user);
  const [selectedTenantName, setSelectedTenantName] = useState(null);
  const [tenantList, setTenantList] = useState([]);
  const [addUrlConfig, setAddUrlConfig] = useState(false);
  const [progress, setProgress] = useState(0);
  const [downloading, setDownloading] = useState(false);
  const [addSnack, setAddSnack] = useState(false);

  const downloadFile = async () => {
    try {
      setProgress(0);
      setDownloading(true);

      const response = await axios.get(`${NGROK}/api/websites/download`, {
        responseType: "blob",
        onDownloadProgress: (progressEvent) => {
          if (progressEvent.total) {
            const percent = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total,
            );
            setProgress(percent);
          }
        },
      });

      const blob = new Blob([response.data], { type: "application/zip" });
      const url = window.URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "WSWebApp.zip");
      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);

      setDownloading(false);
      setProgress(100);
    } catch (error) {
      console.error("Error downloading the file", error);
      setDownloading(false);
      setProgress(0);
    }
  };

  const handleTenantChange = (value) => {
    const tenant = tenantList.find((tenant) => tenant.tenantName === value);
    if (tenant) {
      setSelectedTenantName(tenant.tenantName);
      myLocalStorage.setItem("latestTenant", tenant);
    }
  };

  const createdURL = () => {
    setAddSnack(true);
  };

  useEffect(() => {
    if (tenantList.length === 1) {
      setSelectedTenantName(tenantList[0].tenantName);
    } else if (tenantList.length > 1) {
      const latestTenantName =
        myLocalStorage.getItem("latestTenant")?.tenantName;
      const tenant = tenantList.find(
        (tenant) => tenant.tenantName === latestTenantName,
      );
      setSelectedTenantName(
        tenant ? tenant.tenantName : tenantList[0].tenantName,
      );
    }
  }, [tenantList]);

  useEffect(() => {
    const fetchTenants = async () => {
      const response = await axios.get(`${NGROK}/api/get-all-domains`);
      setTenantList(response.data);
    };
    fetchTenants();
  }, [userData]);

  return (
    <>
      <Snackbar
        open={addSnack}
        autoHideDuration={6000}
        onClose={() => setAddSnack(false)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={() => setAddSnack(false)}
          severity="success"
          variant="filled"
          sx={{ width: "100%" }}
        >
          Created Web Url
        </Alert>
      </Snackbar>
      <Stack spacing={5}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            gap: "15px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            {tenantList.length > 0 && (
              <Autocomplete
                disablePortal
                value={selectedTenantName || " "}
                options={tenantList.map((tenant) => tenant.tenantName)}
                sx={{ width: 300 }}
                renderInput={(params) => (
                  <TextField {...params} label="Tenant List" />
                )}
                onChange={(e, value) => handleTenantChange(value)}
              />
            )}
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              gap: "15px",
            }}
          >
            <Button
              variant="outlined"
              startIcon={<ArrowDownwardIcon />}
              onClick={downloadFile}
              disabled={downloading}
              sx={{ position: "relative", minWidth: 200 }}
            >
              {downloading ? (
                <>
                  {progress > 0 ? `${progress}% Downloaded` : "Downloading..."}
                  <CircularProgress size={20} sx={{ marginLeft: 1 }} />
                </>
              ) : (
                "Download Windows Setup"
              )}
            </Button>

            {userData.role !== "TENANT_USER" && (
              <Button
                variant="outlined"
                startIcon={<AddIcon />}
                onClick={() => setAddUrlConfig(true)}
              >
                Add URL
              </Button>
            )}
          </Box>
        </Box>
        <ConfiguredURLs selectedTenantName={selectedTenantName} />
      </Stack>
      {addUrlConfig && (
        <AddURLModal
          open={addUrlConfig}
          onClose={() => setAddUrlConfig(false)}
          selectedTenantName={selectedTenantName}
          createdURL={createdURL}
        />
      )}
    </>
  );
};

export default WebUrls;
