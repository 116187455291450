import axios from "axios";
import React, { useEffect, useState } from "react";
import { NGROK } from "../../../../APIs";
import {
  Box,
  CircularProgress,
  FormControlLabel,
  List,
  ListItem,
  ListItemText,
  Paper,
  Stack,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import moment from "moment";
import { makeStyles } from "@mui/styles";
import { useMaxStyles } from "../../../../GenericMethods";

const AzureVMInstances = ({ tenantName }) => {
  const [loading, setLoading] = useState(false);
  const [vmInstance, setVMInstances] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedVM, setSelectedVM] = useState(null);
  const classes = useMaxStyles();

  const handlePublishToggle = async (event, azureVmId, id) => {
    const published = event.target.checked;
    setVMInstances((prevData) =>
      prevData.map((vm) =>
        vm.azureVmId === azureVmId ? { ...vm, published } : vm,
      ),
    );

    let requestData = {
      resourceId: azureVmId,
      wsTenantName: tenantName,
      flag: event.target.checked,
      type: "VIRTUAL_MACHINE",
    };

    const response = await axios.patch(
      `${NGROK}/api/azureResources/v1/publish`,
      requestData,
    );
  };

  const fetchVMInstances = async (isFirst = false, tenantName) => {
    if (isFirst) setLoading(true);
    try {
      const response = await axios.get(
        `${NGROK}/api/azureResources/v1/all?type=VIRTUAL_MACHINE&tenantName=${tenantName}`,
      );
      if (response?.data) {
        setVMInstances(response?.data);
      }
    } catch (e) {
      setVMInstances([]);
    }

    setLoading(false);
  };
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const selectedVMandDetails = (vmID) => {
    setSelectedVM(vmID);
  };
  const filteredvmsDatas = vmInstance?.filter((vmname) =>
    vmname?.name.toLowerCase().includes(searchQuery.toLowerCase()),
  );
  const filteredData = vmInstance.filter((vm) => vm.azureVmId === selectedVM);
  useEffect(() => {
    if (tenantName != null) fetchVMInstances(true, tenantName);
  }, [tenantName]);

  return (
    <>
      <Stack direction={"row"} spacing={5}>
        <Box width={"40%"}>
          <TableContainer
            component={Paper}
            sx={{
              overflow: "auto",
              height: "100%",
            }}
            className={classes.root}
          >
            <Table
              sx={{
                height: "fit-content",
                "& th": {
                  background: "#233044",
                  color: "#fff",
                  position: "sticky",
                  top: 0,
                  zIndex: 1,
                },
                "& td, & th": {
                  borderBottom: "1px solid #233044",
                  fontSize: "18px",
                },
              }}
              size="large"
              aria-label="sticky table"
              className={classes.table}
            >
              <TableHead>
                <TableRow>
                  <TableCell colSpan={7} align="left">
                    VM Names
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {loading ? (
                  <TableRow>
                    <TableCell colSpan={7} align="center">
                      <CircularProgress />
                    </TableCell>
                  </TableRow>
                ) : vmInstance.length > 0 ? (
                  <>
                    <TableRow
                      sx={{
                        position: "sticky",
                        top: "54px",
                        backgroundColor: "#f5f5f5",
                        zIndex: 1,
                      }}
                    >
                      <TableCell colSpan={7}>
                        <TextField
                          variant="outlined"
                          placeholder="Search VMs"
                          value={searchQuery}
                          onChange={handleSearchChange}
                          fullWidth
                        />
                      </TableCell>
                    </TableRow>
                    {filteredvmsDatas.map((vmsData, index) => (
                      <TableRow
                        key={index}
                        selected={vmsData.azureVmId === selectedVM}
                        onClick={() => {
                          selectedVMandDetails(vmsData.azureVmId);
                        }}
                        sx={{
                          "&:hover": {
                            background: "#f5f5f5",
                            cursor: "pointer",
                          },
                          "&.Mui-selected": {
                            background: "#233044 !important",
                            "& td, & th": {
                              color: "#fff",
                            },
                          },
                          "& td, & th": {
                            borderBottom: "1px solid #233044",
                            fontSize: "18px",
                          },
                        }}
                      >
                        <TableCell>
                          <Box
                            display="flex"
                            alignItems="center"
                            justifyContent={"space-between"}
                          >
                            <Box ml={2}>
                              <Typography
                                variant="h6"
                                sx={{
                                  fontSize: "18px",
                                }}
                              >
                                {vmsData.name}
                              </Typography>
                            </Box>
                            <Box ml={2}>
                              <FormControlLabel
                                control={
                                  <Switch
                                    checked={vmsData.published}
                                    onChange={(e) =>
                                      handlePublishToggle(
                                        e,
                                        vmsData.azureVmId,
                                        vmsData.id,
                                      )
                                    }
                                    name="publishSwitch"
                                  />
                                }
                                label="Publish"
                              />
                            </Box>
                          </Box>
                        </TableCell>
                      </TableRow>
                    ))}
                  </>
                ) : (
                  <TableRow>
                    <TableCell colSpan={7} align="center">
                      <Typography align="center">No VMs Found</Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        {selectedVM && filteredData ? (
          <Box
            width="50%"
            sx={{
              border: "1px solid",
            }}
          >
            <Typography
              variant="h6"
              gutterBottom
              sx={{
                background: "#233044",
                color: "#fff",
                padding: "10px",
                borderRadius: "5px",
                fontSize: "18px",
                p: 4.8,
              }}
            >
              Details of {filteredData[0]?.name}
            </Typography>
            <List>
              {filteredData.length > 0 ? (
                filteredData.map((vm, index) => (
                  <div key={index}>
                    <ListItem>
                      <ListItemText
                        primary={
                          <Typography sx={{ fontSize: 18 }}>
                            <strong>VM Name</strong>
                          </Typography>
                        }
                        secondary={
                          <Typography sx={{ fontSize: 18 }}>
                            {vm.name || "-"}
                          </Typography>
                        }
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        primary={
                          <Typography sx={{ fontSize: 18 }}>
                            <strong>Azure ID</strong>
                          </Typography>
                        }
                        secondary={
                          <Typography sx={{ fontSize: 18 }}>
                            {vm.azureVmId || "-"}
                          </Typography>
                        }
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        primary={
                          <Typography sx={{ fontSize: 18 }}>
                            <strong>Instance Id</strong>
                          </Typography>
                        }
                        secondary={
                          <Typography
                            sx={{
                              fontSize: 18,
                              wordWrap: "break-word",
                              whiteSpace: "normal",
                            }}
                          >
                            {vm.instanceId || "-"}
                          </Typography>
                        }
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        primary={
                          <Typography sx={{ fontSize: 18 }}>
                            <strong>Computer Name</strong>
                          </Typography>
                        }
                        secondary={
                          <Typography sx={{ fontSize: 18 }}>
                            {vm.computerName || "-"}
                          </Typography>
                        }
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        primary={
                          <Typography sx={{ fontSize: 18 }}>
                            <strong>Power State</strong>
                          </Typography>
                        }
                        secondary={
                          <Typography sx={{ fontSize: 18 }}>
                            {vm.powerState || "-"}
                          </Typography>
                        }
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        primary={
                          <Typography sx={{ fontSize: 18 }}>
                            <strong>Size</strong>
                          </Typography>
                        }
                        secondary={
                          <Typography sx={{ fontSize: 18 }}>
                            {vm.size || "-"}
                          </Typography>
                        }
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        primary={
                          <Typography sx={{ fontSize: 18 }}>
                            <strong>OS Type</strong>
                          </Typography>
                        }
                        secondary={
                          <Typography sx={{ fontSize: 18 }}>
                            {vm.osType || "-"}
                          </Typography>
                        }
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        primary={
                          <Typography sx={{ fontSize: 18 }}>
                            <strong>Public Ip InstanceId</strong>
                          </Typography>
                        }
                        secondary={
                          <Typography
                            sx={{
                              fontSize: 18,
                              wordWrap: "break-word",
                              whiteSpace: "normal",
                            }}
                          >
                            {vm.publicIpInstanceId || "-"}
                          </Typography>
                        }
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        primary={
                          <Typography sx={{ fontSize: 18 }}>
                            <strong>Group Name</strong>
                          </Typography>
                        }
                        secondary={
                          <Typography sx={{ fontSize: 18 }}>
                            {vm.resourceGroupName || "-"}
                          </Typography>
                        }
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        primary={
                          <Typography sx={{ fontSize: 18 }}>
                            <strong>Disk Size</strong>
                          </Typography>
                        }
                        secondary={
                          <Typography sx={{ fontSize: 18 }}>
                            {vm.osDiskSize || "-"}
                          </Typography>
                        }
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        primary={
                          <Typography sx={{ fontSize: 18 }}>
                            <strong>Region</strong>
                          </Typography>
                        }
                        secondary={
                          <Typography sx={{ fontSize: 18 }}>
                            {vm.region || "-"}
                          </Typography>
                        }
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        primary={
                          <Typography sx={{ fontSize: 18 }}>
                            <strong>Security Type</strong>
                          </Typography>
                        }
                        secondary={
                          <Typography sx={{ fontSize: 18 }}>
                            {vm.securityType || "-"}
                          </Typography>
                        }
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        primary={
                          <Typography sx={{ fontSize: 18 }}>
                            <strong>Type</strong>
                          </Typography>
                        }
                        secondary={
                          <Typography sx={{ fontSize: 18 }}>
                            {vm.type || "-"}
                          </Typography>
                        }
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        primary={
                          <Typography sx={{ fontSize: 18 }}>
                            <strong>Ip Address</strong>
                          </Typography>
                        }
                        secondary={
                          <Typography sx={{ fontSize: 18 }}>
                            {vm.ipAddress || "-"}
                          </Typography>
                        }
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        primary={
                          <Typography sx={{ fontSize: 18 }}>
                            <strong>Synced At</strong>
                          </Typography>
                        }
                        secondary={
                          <Typography sx={{ fontSize: 18 }}>
                            {moment(vm.syncedAt).format("YYYY-MM-DD HH:mm") ||
                              "-"}
                          </Typography>
                        }
                      />
                    </ListItem>
                  </div>
                ))
              ) : (
                <Typography variant="body1"> No details available</Typography>
              )}
            </List>
          </Box>
        ) : null}
      </Stack>
    </>
  );
};

export default AzureVMInstances;
